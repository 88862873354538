/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  FDepartment,
  FDepartmentControllerFindParams
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const fDepartmentControllerFind = (
    params?: FDepartmentControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<FDepartment[]>(
      {url: `/departments`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFDepartmentControllerFindQueryKey = (params?: FDepartmentControllerFindParams,) => {
    return [`/departments`, ...(params ? [params]: [])] as const;
    }

    
export const getFDepartmentControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof fDepartmentControllerFind>>, TError = ErrorType<unknown>>(params?: FDepartmentControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fDepartmentControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFDepartmentControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fDepartmentControllerFind>>> = ({ signal }) => fDepartmentControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fDepartmentControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type FDepartmentControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof fDepartmentControllerFind>>>
export type FDepartmentControllerFindQueryError = ErrorType<unknown>

export const useFDepartmentControllerFind = <TData = Awaited<ReturnType<typeof fDepartmentControllerFind>>, TError = ErrorType<unknown>>(
 params?: FDepartmentControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fDepartmentControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFDepartmentControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



