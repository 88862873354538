import { Formik } from 'formik';
import { useKhovtTransPhieuXuatControllerCreate } from 'main/apis/drc/endpoints/khovt-trans-phieu-xuat-controller/khovt-trans-phieu-xuat-controller';
import { KhovtTransPhieuXuatDetailWithRelations } from 'main/apis/drc/models';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page, ValidationMessage } from 'main/constants';
import { ExportStatus } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  resetSuppliesStoreExportAddState,
  selectSuppliesDetails,
} from 'main/redux/modules/supplies-store-export/supplies-store-export-add-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import SaveExportBillModal from './sub-components/save-export-bill-modal';
import SuppliesStoreExportAddInfo, { SuppliesStoreExportInfo } from './sub-components/supplies-store-export-add-info';
import SuppliesStoreExportAddTable from './sub-components/supplies-store-export-add-table/supplies-store-export-add-table';

const infoInitialValues: SuppliesStoreExportInfo = {
  ghiChu: '',
  khoNhap: '',
  khoXuat: '',
  lyDoXuat: '',
  nguoiTaoPhieu: '',
  //soPhieu: '',
  status: ExportStatus.Open.id,
  thongTinCanhBao: '',
};

const infoValidationSchema = Yup.object().shape({
  //soPhieu: Yup.string().required(ValidationMessage.REQUIRED),
  khoXuat: Yup.string().required(ValidationMessage.REQUIRED),
  khoNhap: Yup.string().required(ValidationMessage.REQUIRED),
  nguoiTaoPhieu: Yup.string().required(ValidationMessage.REQUIRED),
  ngayXuat: Yup.string().required(ValidationMessage.REQUIRED),
});

function SuppliesStoreExportAdd() {
  // React Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const suppliesStoreExportDetails = useAppSelector(selectSuppliesDetails);

  // React State
  //const [isOpenExportCompleteModal, setOpenExportCompleteModal] = useState(false);
  const [isOpenSaveModal, setOpenSaveModal] = useState(false);
  //const [newSuppliesExportBill, setNewSuppliesExportBill] = useState<KhovtTransPhieuXuatWithRelations>({});
  const isValidSuppliesDetails = useMemo(() => {
    if (!suppliesStoreExportDetails || suppliesStoreExportDetails.length === 0) {
      return true;
    }
    for (const suppliesStoreExportDetail of suppliesStoreExportDetails) {
      if (!suppliesStoreExportDetail.soLuongYeuCau || !suppliesStoreExportDetail.soLuongThucTe) {
        return false;
      }
    }
    return true;
  }, [suppliesStoreExportDetails]);

  // React Query
  const { isPending: createdSuppliesExportBillIsPending, mutateAsync: createdSuppliesExportBillMutateAsync } =
    useKhovtTransPhieuXuatControllerCreate();

  // React Effect
  useEffect(() => {
    dispatch(resetSuppliesStoreExportAddState());
  }, [dispatch]);

  // React function
  const toggleSaveModal = useCallback(() => setOpenSaveModal((isOpenSaveModal) => !isOpenSaveModal), []);

  /*
  const toggleExportCompleteModal = useCallback(
    () => setOpenExportCompleteModal((isOpenExportCompleteModal) => !isOpenExportCompleteModal),
    [],
  );
  */

  const handleSaveConfirmClick = useCallback(
    (info: SuppliesStoreExportInfo) => {
      // Close modal
      toggleSaveModal();

      // Create new bill
      createdSuppliesExportBillMutateAsync({
        data: {
          ...info,
          details: normalizeSuppliesDetails(suppliesStoreExportDetails),
        },
      })
        .then((newSuppliesExportBill) => {
          navigate(Page.SUPPLIES_STORE_EXPORT_DETAIL.PATH.replace(':id', String(newSuppliesExportBill.id)));
        })
        .catch((e) => {
          toast.error(e?.response?.data?.error?.message || e.message);
        });
    },
    [createdSuppliesExportBillMutateAsync, navigate, suppliesStoreExportDetails, toggleSaveModal],
  );

  /*
  const handleExportClick = useCallback(
    (info: SuppliesStoreExportInfo) => {
      // Create new bill with Exported status
      createdSuppliesExportBillMutateAsync({
        data: {
          ...info,
          status: ExportStatus.Exported.id,
          details: normalizeSuppliesDetails(suppliesStoreExportDetails),
        },
      })
        .then((newSuppliesExportBill) => {
          setNewSuppliesExportBill(newSuppliesExportBill);
          // Open complete modal
          toggleExportCompleteModal();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.error?.message || e.message);
        });
    },
    [createdSuppliesExportBillMutateAsync, suppliesStoreExportDetails, toggleExportCompleteModal],
  );
  */

  return (
    <BasicTemplate
      pageTitle={Page.SUPPLIES_STORE_EXPORT_ADD.TITLE}
      pageName={Page.SUPPLIES_STORE_EXPORT_ADD.NAME}
      parentPage={{
        name: Page.SUPPLIES_STORE_EXPORT_LIST.NAME,
        path: Page.SUPPLIES_STORE_EXPORT_LIST.PATH,
      }}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Formik
              initialValues={infoInitialValues}
              validationSchema={infoValidationSchema}
              onSubmit={() => {
                // do nothing
              }}>
              {(formikProps) => (
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-center">TẠO PHIẾU XUẤT</h3>
                  </CardHeader>
                  <CardBody>
                    <SuppliesStoreExportAddInfo formikProps={formikProps} />
                    <SuppliesStoreExportAddTable />
                    <SaveExportBillModal
                      isOpen={isOpenSaveModal}
                      toggle={toggleSaveModal}
                      handleConfirmClick={() => handleSaveConfirmClick(formikProps.values)}
                    />
                    {/*
                    <ExportCompleteModal
                      isOpen={isOpenExportCompleteModal}
                      toggle={toggleExportCompleteModal}
                      handleConfirmClick={() =>
                        navigate(
                          Page.SUPPLIES_STORE_EXPORT_DETAIL.PATH.replace(':id', String(newSuppliesExportBill.id)),
                        )
                      }
                    />
                    */}
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex justify-content-end pb-1">
                      <div>
                        <Button
                          color="primary"
                          onClick={toggleSaveModal}
                          disabled={
                            !formikProps.dirty ||
                            !formikProps.isValid ||
                            !isValidSuppliesDetails ||
                            createdSuppliesExportBillIsPending
                          }>
                          Lưu
                        </Button>
                        {/*
                        <Button
                          color="primary"
                          onClick={() => handleExportClick(formikProps.values)}
                          disabled={
                            !formikProps.dirty ||
                            !formikProps.isValid ||
                            !isValidSuppliesDetails ||
                            createdSuppliesExportBillIsPending
                          }>
                          Xuất hàng
                        </Button>
                        */}
                        <Button color="secondary" onClick={() => navigate(Page.SUPPLIES_STORE_EXPORT_LIST.PATH)}>
                          Hủy
                        </Button>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              )}
            </Formik>
          </div>
        </Row>
      </Container>
    </BasicTemplate>
  );
}

export const normalizeSuppliesDetails = (suppliesDetails: KhovtTransPhieuXuatDetailWithRelations[]) => {
  const list: KhovtTransPhieuXuatDetailWithRelations[] = [];
  for (const suppliesDetail of suppliesDetails) {
    list.push({
      idNhap: suppliesDetail.idNhap,
      maLo: suppliesDetail.maLo,
      maVt: suppliesDetail.maVt,
      tenVt: suppliesDetail.tenVt,
      nguonGoc: suppliesDetail.nguonGoc,
      tonKho: suppliesDetail.tonKho,
      soLuongThucTe: Number(suppliesDetail.soLuongThucTe),
      soLuongYeuCau: Number(suppliesDetail.soLuongYeuCau),
      ngayXuat: suppliesDetail.ngayXuat,
    });
  }
  return list;
};

export default SuppliesStoreExportAdd;
