import { useKhotpMasterKhoControllerFind } from 'main/apis/drc/endpoints/khotp-master-kho-controller/khotp-master-kho-controller';
import { KhotpMasterKhoControllerFindParams } from 'main/apis/drc/models';
import { useAppSelector } from 'main/redux/hooks';
import { useMemo } from 'react';
import { TableSelectOptions } from '../types/table-select-options';

function useProductStoreOptions(includeAllOption?: boolean, allowedKho: boolean = false) {
  const dsKhoTpPermission = useAppSelector((state) => state.auth.dsKhoTpPermission);
  const filter: KhotpMasterKhoControllerFindParams =
    dsKhoTpPermission && allowedKho
      ? {
          filter: {
            where: {
              maKho: {
                inq: dsKhoTpPermission,
              },
            },
          },
        }
      : {};

  // React Query
  const { data: productStores, isLoading: productStoresIsLoading } = useKhotpMasterKhoControllerFind(filter);

  // React Function
  const productStoreOptions = useMemo(() => {
    const options: TableSelectOptions[] = [];

    if (includeAllOption) {
      options.push({ id: '-1', text: 'Tất cả' });
    }

    if (productStores) {
      options.push(
        ...productStores.map((productStore) => ({
          id: productStore.maKho,
          text: productStore.tenKho ?? productStore.maKho,
        })),
      );
    }

    return options;
  }, [includeAllOption, productStores]);

  return { productStoreOptions, productStoresIsLoading };
}

export default useProductStoreOptions;
