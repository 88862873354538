import { useKhovtMasterKhoControllerFind } from 'main/apis/drc/endpoints/khovt-master-kho-controller/khovt-master-kho-controller';
import { useCallback, useMemo } from 'react';

function useSuppliesStoreOptions(includeAllOption?: boolean) {
  // React Query
  const { data: suppliesStores, isLoading: suppliesStoresIsLoading } = useKhovtMasterKhoControllerFind();

  // React Function
  const suppliesStoreOptions = useMemo(() => {
    const options = [];

    if (includeAllOption) {
      options.push({ id: '-1', text: 'Tất cả' });
    }

    if (suppliesStores) {
      options.push(...suppliesStores.map((suppliesStore) => ({ id: suppliesStore.id, text: suppliesStore.tenKho })));
    }

    return options;
  }, [includeAllOption, suppliesStores]);

  const getSuppliesStoreOptionTextById = useCallback(
    (id: number) => {
      if (!suppliesStoreOptions) {
        return '';
      }
      for (const suppliesStoreOption of suppliesStoreOptions) {
        if (suppliesStoreOption.id === id) {
          return suppliesStoreOption.text;
        }
      }
      return '';
    },
    [suppliesStoreOptions],
  );

  return { suppliesStoreOptions, suppliesStoresIsLoading, getSuppliesStoreOptionTextById };
}

export default useSuppliesStoreOptions;
