import { FieldName } from 'main/constants/enums';

const ApiFilterUtil = {
  getFilterKey_khovtTransPhieuNhapControllerFindDetail(fieldName?: string) {
    if (!fieldName) {
      return fieldName;
    }

    switch (fieldName) {
      case FieldName.SO_PHIEU_NHAP:
      case FieldName.DON_VI_NHAP:
      case FieldName.NGAY_NHAP:
      case FieldName.MA_KHO:
        return `phieuNhap.${fieldName}`;
      case FieldName.DVT:
      case FieldName.SO_LUONG:
      case FieldName.SO_LO:
      case FieldName.GHI_CHU:
      case FieldName.STATUS:
        return `nhapDetail.${fieldName}`;
      case FieldName.MA_VT:
      case FieldName.TEN_VT:
        return `vatTu.${fieldName}`;
      case FieldName.MA_NHOM:
        return `nhomVt.${fieldName}`;
    }
  },
};

export { ApiFilterUtil };
