import { FieldName } from 'main/constants/enums';

export const AuthenticationUtil = {
  getAuthToken() {
    return localStorage.getItem(FieldName.AUTH_TOKEN_KEY);
  },

  setAuthToken(token: string) {
    return localStorage.setItem(FieldName.AUTH_TOKEN_KEY, token);
  },

  deleteAuthToken() {
    return localStorage.removeItem(FieldName.AUTH_TOKEN_KEY);
  },

  getUsername() {
    return localStorage.getItem(FieldName.USERNAME_KEY);
  },

  setUsername(username: string) {
    return localStorage.setItem(FieldName.USERNAME_KEY, username);
  },

  deleteUsername() {
    return localStorage.removeItem(FieldName.USERNAME_KEY);
  },

  getDsKhoVtPermission() {
    return localStorage.getItem(FieldName.DS_KHO_VT_PERMISSION);
  },

  setDsKhoVtPermission(dsKhoVtPermission: string | undefined) {
    if (dsKhoVtPermission) {
      return localStorage.setItem(FieldName.DS_KHO_VT_PERMISSION, dsKhoVtPermission);
    } else {
      this.deleteDsKhoVtPermission();
    }
  },

  deleteDsKhoVtPermission() {
    return localStorage.removeItem(FieldName.DS_KHO_VT_PERMISSION);
  },

  getDsKhoTpPermission() {
    return localStorage.getItem(FieldName.DS_KHO_TP_PERMISSION);
  },

  setDsKhoTpPermission(dsKhoTpPermission: string | undefined) {
    if (dsKhoTpPermission) {
      return localStorage.setItem(FieldName.DS_KHO_TP_PERMISSION, dsKhoTpPermission);
    } else {
      this.deleteDsKhoTpPermission();
    }
  },

  deleteDsKhoTpPermission() {
    return localStorage.removeItem(FieldName.DS_KHO_TP_PERMISSION);
  },
};
