import { useAuthenticationControllerLogin } from 'main/apis/drc/endpoints/authentication-controller/authentication-controller';
import { Credentials, UserExcludingPassword } from 'main/apis/drc/models';
import { useAppDispatch } from 'main/redux/hooks';
import { authReset, setAuth } from 'main/redux/modules/auth/auth-slice';
import { AuthenticationUtil } from 'main/utils';
import { useState } from 'react';

type Props = {
  onSuccess?: () => void;
  onError?: (errorMessage?: string) => void;
};

const useAuth = (props?: Props) => {
  const [userInfo, setUserInfo] = useState<UserExcludingPassword | null>(null);
  const [authenticated, setAuthenticated] = useState(true);
  const loginMutation = useAuthenticationControllerLogin();
  const dispatch = useAppDispatch();

  const login = (credential: Credentials) => {
    loginMutation.mutate(
      { data: credential },
      {
        onSuccess: (data) => {
          if (data.token) {
            AuthenticationUtil.setAuthToken(data.token);
            AuthenticationUtil.setUsername(credential.email);
            const dsKhoTpPermission = data.dsKhoTpPermission?.split(',') ?? null;
            const dsKhoVtPermission = data.dsKhoVtPermission?.split(',')?.map((maKho) => +maKho) ?? null;
            dispatch(setAuth({ username: credential.email, dsKhoTpPermission, dsKhoVtPermission }));
            setUserInfo({
              username: credential.email,
            });
            setAuthenticated(true);

            if (props?.onSuccess) {
              props.onSuccess();
            }
          } else if (props?.onError) {
            props.onError('Đăng nhập thất bại: Thông tin đăng nhập chưa hợp lệ.');
          }
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.error?.message || error.message;
          if (props?.onError) {
            props.onError(errorMessage);
          }
        },
      },
    );
  };

  const logout = () => {
    AuthenticationUtil.deleteAuthToken();
    AuthenticationUtil.deleteUsername();
    dispatch(authReset());
  };

  return {
    login,
    logout,
    userInfo,
    authenticated,
  };
};

export default useAuth;
