import { useFormik } from 'formik';
import { FTitle, NewFTitle } from 'main/apis/drc/models';
import { Select2Box } from 'main/components/molecules/selectbox';
import { ValidationMessage } from 'main/constants';
import { useProductStoreOptions, useSuppliesStoreOptions } from 'main/hooks';
import React, { useEffect, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import * as Yup from 'yup';

interface UserModalProps {
  isOpen: boolean;
  toggle: () => void;
  handleAdd: (newTitle: NewFTitle) => void;
  handleEdit: (id: number, newTitle: FTitle) => void;
  selectedTitle?: FTitle;
  isAddPending: boolean;
  isUpdatePending: boolean;
}

type TitleFormData = {
  titleName: string;
  dsKhoVtPermission: number[] | null;
  dsKhoTpPermission: string[] | null;
};

const initTitleData: TitleFormData = {
  titleName: '',
  dsKhoVtPermission: null,
  dsKhoTpPermission: null,
};

const TitleModal: React.FC<UserModalProps> = ({
  isOpen,
  toggle,
  handleAdd,
  handleEdit,
  selectedTitle,
  isAddPending,
  isUpdatePending,
}) => {
  const { productStoreOptions } = useProductStoreOptions();
  const { suppliesStoreOptions } = useSuppliesStoreOptions();
  useEffect(() => {
    if (selectedTitle && suppliesStoreOptions && productStoreOptions) {
      // setTimeout 0s to prevent bug Select2Box auto set value to []
      setTimeout(() => {
        formik.setValues({
          ...initTitleData,
          titleName: selectedTitle.name ?? '',
          dsKhoVtPermission: selectedTitle.dsKhoVtPermission?.split(',')?.map((id) => +id) ?? null,
          dsKhoTpPermission: selectedTitle.dsKhoTpPermission?.split(',') ?? null,
        });
      });
    } else {
      resetForm();
    }
  }, [selectedTitle, productStoreOptions, suppliesStoreOptions]);

  const formik = useFormik({
    initialValues: initTitleData,
    validationSchema: Yup.object().shape({
      titleName: Yup.string().required(ValidationMessage.REQUIRED),
      dsKhoVtPermission: Yup.array().nullable(),
      dsKhoTpPermission: Yup.array().nullable(),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const resetForm = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  const handleSubmit = useCallback(
    (titleData: typeof initTitleData) => {
      if (selectedTitle?.name) {
        // Mode: Update Title
        const updatedTitle: FTitle = {
          id: selectedTitle.id,
          name: titleData.titleName,
          dsKhoVtPermission: titleData.dsKhoVtPermission?.join(',') ?? null,
          dsKhoTpPermission: titleData.dsKhoTpPermission?.join(',') ?? null,
        };
        handleEdit(selectedTitle.id!, updatedTitle);
      } else {
        // Mode: Add new Title
        const newTitle: NewFTitle = {
          name: titleData.titleName,
          dsKhoVtPermission: titleData.dsKhoVtPermission?.join(',') ?? null,
          dsKhoTpPermission: titleData.dsKhoTpPermission?.join(',') ?? null,
        };
        handleAdd(newTitle);
      }
      resetForm();
      toggle();
    },
    [handleAdd, handleEdit, resetForm, selectedTitle?.id, selectedTitle?.name, toggle],
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{selectedTitle ? 'Chỉnh sửa' : 'Thêm Mới'}</ModalHeader>
      <ModalBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="titleName">
                  Chức danh <i className="fa fa-asterisk fa-sm text-danger" />
                </Label>
                <Input
                  type="text"
                  name="titleName"
                  id="titleName"
                  value={formik.values.titleName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  invalid={
                    !!formik.errors.titleName && (formik.getFieldMeta('titleName').touched || !!formik.submitCount)
                  }
                />
                {formik.errors.titleName && (formik.getFieldMeta('titleName').touched || !!formik.submitCount) && (
                  <FormFeedback>{formik.errors.titleName}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="dsKhoVtPermission">Danh sách Kho vật tư được quyền thao tác</Label>
                <div>
                  <small className="font-italic">Để trống nếu cho phép truy cập tất cả các kho vật tư</small>
                </div>
                <Select2Box
                  multiple
                  name="dsKhoVtPermission"
                  id="dsKhoVtPermission"
                  className="form-control"
                  options={{ placeholder: 'Chọn Kho vật tư' }}
                  value={formik.values.dsKhoVtPermission}
                  onChange={formik.handleChange}
                  data={suppliesStoreOptions}
                  invalid={!!formik.errors.dsKhoVtPermission}
                />
                {formik.errors.dsKhoVtPermission && (
                  <FormFeedback className="d-block">{formik.errors.dsKhoVtPermission}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="dsKhoTpPermission">Danh sách Kho sản phẩm được quyền thao tác</Label>
                <div>
                  <small className="font-italic">Để trống nếu cho phép truy cập tất cả các kho sản phẩm</small>
                </div>
                <Select2Box
                  multiple
                  name="dsKhoTpPermission"
                  id="dsKhoTpPermission"
                  className="form-control"
                  options={{ placeholder: 'Chọn Kho sản phẩm' }}
                  value={formik.values.dsKhoTpPermission}
                  onChange={formik.handleChange}
                  data={productStoreOptions}
                  invalid={!!formik.errors.dsKhoTpPermission}
                />
                {formik.errors.dsKhoTpPermission && (
                  <FormFeedback className="d-block">{formik.errors.dsKhoTpPermission}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => formik.submitForm()}
          color="primary"
          disabled={!formik.dirty || !formik.isValid || isAddPending || isUpdatePending}>
          {isAddPending || isUpdatePending ? 'Đang lưu...' : 'Lưu'}
        </Button>
        <Button type="reset" color="secondary" onClick={toggle}>
          Hủy
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TitleModal;
