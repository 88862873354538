import {
  useKhotpMasterKhachHangControllerCount,
  useKhotpMasterKhachHangControllerCreate,
  useKhotpMasterKhachHangControllerDeleteById,
  useKhotpMasterKhachHangControllerFind,
  useKhotpMasterKhachHangControllerUpdateById,
} from 'main/apis/drc/endpoints/khotp-master-khach-hang-controller/khotp-master-khach-hang-controller';
import { KhotpMasterKhachHangWithRelations } from 'main/apis/drc/models/khotpMasterKhachHangWithRelations';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import DeleteModal from 'main/components/organisms/model/delete-modal';
import React from 'react';
import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import AddEditItemModal from '../prdouct-customer/prdouct-customer-add-edit';
import { Link } from 'react-router-dom';

const ProductCustomerExportList: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchCustomerName, setSearchCustomerName] = useState('');
  const [addEditModal, setAddEditModal] = useState(false);
  const [items, setItems] = useState<KhotpMasterKhachHangWithRelations[]>([]);
  const [currentItem, setCurrentItem] = useState<KhotpMasterKhachHangWithRelations | null>(null);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dataCustomer, setDataCustomer] = useState<string[]>([]);

  const deleteMutation = useKhotpMasterKhachHangControllerDeleteById();
  const createMutation = useKhotpMasterKhachHangControllerCreate();
  const updateMutation = useKhotpMasterKhachHangControllerUpdateById();

  const [filter, setFilter] = useState({
    where: {} as any,
    fields: {
      diaChiKh: true,
      dtKh: true,
      ghiChu: true,
      khuVucKh: true,
      lienHeKh: true,
      maKh: true,
      tenKh: true,
      mstKh: true,
      faxKh: true,
    },
    limit: sizePerPage,
    skip: (page - 1) * sizePerPage,
  });

  const { data: countItems, refetch: refetchCount } = useKhotpMasterKhachHangControllerCount({
    where: filter.where,
  });

  const { data, refetch } = useKhotpMasterKhachHangControllerFind(
    {
      filter: filter,
    },
    {
      query: {
        staleTime: 0,
      },
    },
  );

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  useEffect(() => {
    if (countItems && countItems.count !== undefined) {
      setTotalItems(countItems.count);
    }
  }, [countItems]);

  const handleSearch = () => {
    const newWhere: any = {};
    if (searchCustomerName) newWhere.tenKh = { like: `%${searchCustomerName}%` };
    setFilter((prevFilter) => ({
      ...prevFilter,
      where: newWhere,
      skip: 0,
    }));
    setPage(1);
    refetch();
    refetchCount();
  };

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setFilter((prevFilter) => ({
      ...prevFilter,
      limit: sizePerPage,
      skip: (page - 1) * sizePerPage,
    }));
    refetch();
    refetchCount();
  };

  const updateItem = async (id: string, updatedItem: Partial<KhotpMasterKhachHangWithRelations>) => {
    updateMutation.mutate(
      { id: String(id), data: updatedItem },
      {
        onSuccess: () => {
          setItems(items.map((item) => (item.maKh === id ? { ...item, ...updatedItem } : item)));
          refetch();
        },
        onError: (error) => {
          console.error('Error updating item:', error);
        },
      },
    );
  };

  const toggleModal = () => setModal(!modal);

  const paginationOptions = paginationFactory({
    page,
    sizePerPage,
    totalSize: totalItems,
    showTotal: true,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Hiển thị{' '}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={currSizePerPage}
              onChange={(e) => onSizePerPageChange(Number(e.target.value))}>
              {options.map((option) => (
                <option key={option.text} value={option.page}>
                  {option.text}
                </option>
              ))}
            </select>
          }{' '}
          mục.
        </label>
      </div>
    ),
    paginationTotalRenderer: (from: number, to: number, size: number) => (
      <span className="react-bootstrap-table-pagination-total">
        &nbsp;Hiển thị hàng {from} đến {to} trên {size}
      </span>
    ),
    onPageChange: (page) => handleTableChange('pagination', { page, sizePerPage }),
    onSizePerPageChange: (sizePerPage, page) => handleTableChange('pagination', { page, sizePerPage }),
  });

  const handleAddNewModal = () => {
    setCurrentItem(null);
    setAddEditModal(true);
  };

  const handleShowEditModal = (item: KhotpMasterKhachHangWithRelations) => {
    setCurrentItem(item);
    setAddEditModal(true);
  };
  const handleShowDeleteModal = (item: KhotpMasterKhachHangWithRelations) => {
    setCurrentItem(item);
    toggleModal();
  };
  const handleDelete = async () => {
    if (currentItem) {
      deleteMutation.mutate(
        { id: String(currentItem.maKh) },
        {
          onSuccess: () => {
            setSearchCustomerName('');
            setFilter(filter);
            setPage(1);
            refetch();
            refetchCount();
            toggleModal();
          },
          onError: (error) => {
            console.error('Error deleting item:', error);
          },
        },
      );
    }
  };

  const toggleAddEditModal = () => {
    setCurrentItem(null);
    setAddEditModal(!addEditModal);
  };

  const handleAddNewItem = async (item: {
    diaChiKh: string;
    dtKh: string;
    ghiChu: string;
    khuVucKh: string;
    lienHeKh: string;
    maKh: string;
    tenKh: string;
    mstKh: string;
    faxKh: string;
  }) => {
    createMutation.mutate(
      { data: item },
      {
        onSuccess: (newItem) => {
          setSearchCustomerName('');
          setFilter(filter);
          setPage(1);
          refetch();
          refetchCount();
          toggleAddEditModal();
        },
        onError: (error) => {
          console.error('Error adding new item:', error);
        },
      },
    );
  };

  const fieldArray = [
    {
      dataField: 'maKh',
      text: 'Mã khách hàng',
      sort: false,
      style: { width: '20%' },
    },
    {
      dataField: 'tenKh',
      text: 'Tên khách hàng',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'diaChiKh',
      text: 'Địa chỉ',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'mstKh',
      text: 'Mã số thuế',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'dtKh',
      text: 'Số điện thoại',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'lienHeKh',
      text: 'Người liên hệ',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'ghiChu',
      text: 'Ghi chú',
      sort: false,
      style: { width: '10%' },
    },
    {
      dataField: 'thaoTac',
      text: 'Thao Tác',
      editable: false,
      style: { width: '10%' },
      formatter: (cell: any, row: KhotpMasterKhachHangWithRelations) => (
        <>
          <Link className="px-3" to="#" onClick={() => handleShowEditModal(row)}>
            <i className="fa-solid fa-pen-to-square" title="Sửa" />
          </Link>
          <Link className="px-3" to="#" onClick={() => handleShowDeleteModal(row)}>
            <i className="fa-solid fa-trash" title="Xóa" />
          </Link>
        </>
      ),
    },
  ];

  return (
    <BasicTemplate pageTitle={Page.PRODUCTS_CUSTOMER_LIST.TITLE} pageName={Page.PRODUCTS_CUSTOMER_LIST.NAME}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>QUẢN LÝ KHÁCH HÀNG</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="tenKhachHangSearch">Tên khách hàng</Label>
                      <Input
                        type="text"
                        id="tenKhachHangSearch"
                        value={searchCustomerName}
                        onChange={(e) => setSearchCustomerName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col md={2} className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleSearch}>
                      Tìm
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row className="justify-content-end mb-5">
                  <Col md={2} className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAddNewModal}>
                      Thêm mới
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <ToolkitProvider data={items} keyField="maKh" columns={fieldArray} search>
                        {(props: any) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={paginationOptions}
                              remote
                              onTableChange={handleTableChange}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Card>
                  </Col>
                </Row>
                {currentItem && (
                  <DeleteModal
                    messageItem={currentItem.tenKh || ''}
                    modal={modal}
                    toggle={toggleModal}
                    handleDelete={handleDelete}
                  />
                )}
                <AddEditItemModal
                  isOpen={addEditModal}
                  toggle={toggleAddEditModal}
                  handleAdd={handleAddNewItem}
                  handleEdit={updateItem}
                  currentItem={currentItem}
                  dataNhomSP={dataCustomer}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </BasicTemplate>
  );
};

export default ProductCustomerExportList;
