import { KhovtMasterVtWithRelations } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  selectSuppliesTable,
  selectSuppliesTableSelectedRowIds,
  setSuppliesTableSelectedRows,
  updateSuppliesTable,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-add-slice';
import { useCallback } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import tableConfig from './table-config';

type Props = {
  data?: KhovtMasterVtWithRelations[];
  dataIsLoading: boolean;
  dataCount?: number;
  dataCountIsLoading: boolean;
};

function AddSuppliesModalTable({ data, dataIsLoading, dataCount, dataCountIsLoading }: Props) {
  // Redux
  const dispatch = useAppDispatch();
  const selectedRowIds = useAppSelector(selectSuppliesTableSelectedRowIds);
  const { page, sizePerPage, sortField, sortOrder, selectedRows } = useAppSelector(selectSuppliesTable);

  // React Function
  const handleOnSelect = useCallback(
    (row: KhovtMasterVtWithRelations, isSelect: boolean) => {
      if (!row || !row.maVt || !data) {
        return;
      }

      // Sync selected rows with data table
      const newSelectedRows = getSyncSelectedRows(data, selectedRows);

      if (isSelect) {
        dispatch(setSuppliesTableSelectedRows([...newSelectedRows, row]));
      } else {
        dispatch(setSuppliesTableSelectedRows(newSelectedRows.filter((item) => item.maVt !== row.maVt)));
      }
    },
    [data, dispatch, selectedRows],
  );

  const handleOnSelectAll = useCallback(
    (isSelect: boolean, rows: KhovtMasterVtWithRelations[]) => {
      if (isSelect) {
        dispatch(setSuppliesTableSelectedRows(rows));
      } else {
        dispatch(setSuppliesTableSelectedRows([]));
      }
    },
    [dispatch],
  );

  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<KhovtMasterVtWithRelations>) => {
      const { page, sizePerPage, sortField, sortOrder } = newState;

      dispatch(
        updateSuppliesTable({
          page,
          sizePerPage,
          sortField,
          sortOrder,
          selectedRows: getSyncSelectedRows(data, selectedRows),
        }),
      );
    },
    [data, dispatch, selectedRows],
  );

  return (
    <ToolkitProvider data={data || []} keyField={FieldName.MA_VT} columns={tableConfig.columns}>
      {(props: any) => (
        <>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              sort={
                sortField
                  ? {
                      dataField: sortField,
                      order: sortOrder,
                    }
                  : {}
              }
              selectRow={{
                ...tableConfig.selectRow,
                selected: selectedRowIds,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll,
              }}
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading || dataCountIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
}

const getSyncSelectedRows = (data?: KhovtMasterVtWithRelations[], selectedRows?: KhovtMasterVtWithRelations[]) => {
  if (!selectedRows || !data) {
    return [];
  }
  const newSelectedRows = [...selectedRows];
  for (const selectedRow of selectedRows) {
    const rowDataIndex = data.findIndex((item) => item.maVt === selectedRow.maVt);
    if (rowDataIndex === -1) {
      const removedIndex = newSelectedRows.findIndex((item) => selectedRow.maVt === item.maVt);
      newSelectedRows.splice(removedIndex, 1);
    }
  }
  return newSelectedRows;
};

export default AddSuppliesModalTable;
