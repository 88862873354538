import { FieldName } from 'main/constants/enums';
import { generateColumnStyle } from 'main/utils';
import { SizePerPageRendererOptions } from 'react-bootstrap-table-next';

const columns = [
  {
    dataField: FieldName.TEN_NHOM,
    text: 'Nhóm',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.MA_VT,
    text: 'Mã vật tư',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.TEN_VT,
    text: 'Tên vật tư',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.MA_KHO,
    text: 'Kho Nhập/Xuất',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.SO_LUONG_NHAP_PREVIOUS_MONTH,
    text: 'Nhập tháng trước',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.SO_LUONG_XUAT_PREVIOUS_MONTH,
    text: 'Xuất tháng trước',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.SO_LUONG_NHAP_MONTH,
    text: 'Nhập tháng này',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.SO_LUONG_XUAT_MONTH,
    text: 'Xuất tháng này',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.TON_KHO,
    text: 'Tổng tồn kho',
    sort: true,
    ...generateColumnStyle(),
  },
];

const pagination = {
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: true,
  sizePerPageRenderer: ({ onSizePerPageChange }: SizePerPageRendererOptions) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Hiển thị&nbsp;
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(Number(e.target.value))}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        &nbsp;mục.
      </label>
    </div>
  ),
  paginationTotalRenderer: (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;Hiển thị hàng {from} đến {to} trên {size}
    </span>
  ),
};

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  clickToEdit: true,
  style: { backgroundColor: '#c8e6c9' },
};

const tableConfig = {
  columns,
  pagination,
  selectRow,
};

export default tableConfig;
