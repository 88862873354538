import {
  KhovtTransPhieuNhapControllerFindDetail200DataItem,
  KhovtTransPhieuXuatDetailWithRelations,
} from 'main/apis/drc/models';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  deleteSuppliesDetail,
  insertNewSupplies,
  selectSuppliesDetails,
  selectSuppliesFilter,
  updateSuppliesDetail,
} from 'main/redux/modules/supplies-store-export/supplies-store-export-add-slice';
import { generateColumnStyle } from 'main/utils';
import { useCallback, useState } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import AddSuppliesModal from './add-supplies-modal/add-supplies-modal';
import tableConfig from './table-config';

function SuppliesStoreExportAddTable() {
  // Redux
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectSuppliesDetails);
  const filter = useAppSelector(selectSuppliesFilter);

  // React State
  const [isOpenAddSuppliesModal, setOpenAddSuppliesModal] = useState(false);

  // React function
  const toggleAddSuppliesModal = useCallback(
    () => setOpenAddSuppliesModal((isOpenAddSuppliesModal) => !isOpenAddSuppliesModal),
    [],
  );

  const handleAddSuppliesModalConfirmClick = useCallback(
    (selectedSuppliesRows?: KhovtTransPhieuNhapControllerFindDetail200DataItem[]) => {
      // Update state
      dispatch(insertNewSupplies(selectedSuppliesRows));

      // Close modal
      toggleAddSuppliesModal();
    },
    [dispatch, toggleAddSuppliesModal],
  );

  const handleDeleteSuppliesDetails = useCallback(
    (idNhap?: number | null | undefined) => {
      dispatch(deleteSuppliesDetail(idNhap));
    },
    [dispatch],
  );

  const handleTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<KhovtTransPhieuXuatDetailWithRelations>) => {
      if (type === 'cellEdit') {
        const {
          cellEdit: { dataField, newValue, rowId },
        } = newState;
        dispatch(updateSuppliesDetail(rowId, dataField, newValue));
      }
    },
    [dispatch],
  );

  return (
    <>
      <ToolkitProvider
        data={suppliesDetails}
        keyField={FieldName.ID_NHAP}
        columns={[
          ...tableConfig.columns,
          {
            dataField: FieldName.ACTION,
            isDummyField: true,
            text: 'Thao tác',
            editable: false,
            ...generateColumnStyle(),
            formatter: (_: any, row: KhovtTransPhieuXuatDetailWithRelations) => {
              return (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteSuppliesDetails(row.idNhap);
                  }}>
                  <i className="fa-solid fa-trash" title="Xóa" />
                </Link>
              );
            },
          },
        ]}>
        {(props: any) => (
          <>
            <div className="d-flex justify-content-end pb-4">
              <Button color="primary" onClick={toggleAddSuppliesModal} disabled={!filter.suppliesStoreCode}>
                Thêm vật tư
              </Button>
            </div>
            <div className="table-responsive">
              <BootstrapTable
                {...props.baseProps}
                remote
                bootstrap4={true}
                bordered={false}
                cellEdit={cellEditFactory(tableConfig.cellEdit)}
                onTableChange={handleTableChange}
                noDataIndication={() => <div className="text-center">Chưa có vật tư nào</div>}
              />
            </div>
            <p className="text-sm mt-2">
              <em>&#40;Nhấn 2 lần vào ô bạn muốn chỉnh sửa&#41;</em>
            </p>
            <AddSuppliesModal
              isOpen={isOpenAddSuppliesModal}
              toggle={toggleAddSuppliesModal}
              handleConfirmClick={handleAddSuppliesModalConfirmClick}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
}

export default SuppliesStoreExportAddTable;
