import { useFTitleControllerFind } from 'main/apis/drc/endpoints/ftitle-controller/ftitle-controller';
import { useMemo } from 'react';

function useTitleStoreOptions(includeAllOption?: boolean) {
  // React Query
  const { data: titleData, isSuccess } = useFTitleControllerFind();

  // React Function
  const titleStoreOptions = useMemo(() => {
    const options = [];

    if (includeAllOption) {
      options.push({ id: '-1', text: 'Tất cả' });
    }

    if (titleData) {
      options.push(...titleData.map((title) => ({ id: title.id!.toString(), text: title.name })));
    }

    return options;
  }, [includeAllOption, titleData]);

  return { titleStoreOptions, isSuccess };
}

export default useTitleStoreOptions;
