const ImportStatus = {
  Wait: { id: 'Wait', text: 'Chờ đánh giá' },
  Meet: { id: 'Meet', text: 'Đạt tiêu chuẩn' },
  NotMeet: { id: 'NotMeet', text: 'Không đạt tiêu chuẩn' },
  Disposal: { id: 'Disposal', text: 'Chờ thanh lý' },
  Overdue: { id: 'Overdue', text: 'Quá hạn' },
  Out: { id: 'Out', text: 'Hết hàng' },
};

export default ImportStatus;
