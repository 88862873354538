import { useFPermissionControllerUpdateAll } from 'main/apis/drc/endpoints/fpermission-controller/fpermission-controller';
import {
  useFTitleControllerCreate,
  useFTitleControllerFindWithPermissionsById,
  useFTitleControllerUpdateById,
} from 'main/apis/drc/endpoints/ftitle-controller/ftitle-controller';
import { FPermission, FPermissionWithControllerSchema, FTitle, NewFTitle } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { Select2Box } from 'main/components/molecules/selectbox';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import Role from 'main/constants/enums/role';
import useTitleStoreOptions from 'main/hooks/useTitleStoreOptions';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap';
import tableConfig from './table-config';
import TitleModal from './tittle-add-edit-modal';

const TitlePermissionManagement: React.FC = () => {
  const [controllerPermission, setControllerPermission] = useState<FPermissionWithControllerSchema[]>([]);
  const [selectedTitleId, setSelectedTitleId] = useState<string | undefined>(undefined);
  const [selectedTitle, setSelectedTitle] = useState<FTitle | undefined>(undefined);
  const [openTitleModal, setOpenTitleModal] = useState<boolean>(false);
  const [isTitleModalCreatedMode, setIsTitleModalCreatedMode] = useState<boolean>(true);
  const { mutateAsync: updateTitleAsync, isPending: isUpdatePending } = useFTitleControllerUpdateById();
  const { mutateAsync: createTitleAsync, isPending: isAddPending } = useFTitleControllerCreate();

  const {
    data: titlePermissionData,
    refetch: refetchPermission,
    isLoading,
  } = useFTitleControllerFindWithPermissionsById(Number(selectedTitleId));

  const { mutateAsync: updateControllerPermissionAsync, isPending: isUpdating } = useFPermissionControllerUpdateAll();

  const { titleStoreOptions: titleOptions, refetch: refetchListTitle } = useTitleStoreOptions();

  useEffect(() => {
    if (titlePermissionData?.controllerPermissions) {
      setControllerPermission(titlePermissionData.controllerPermissions);
      setSelectedTitle({
        id: titlePermissionData.id,
        name: titlePermissionData.name,
        dsKhoTpPermission: titlePermissionData.dsKhoTpPermission,
        dsKhoVtPermission: titlePermissionData.dsKhoVtPermission,
      });
    }
  }, [titlePermissionData]);

  const handleCheckboxChange = useCallback((id: number, clickType: 'view' | 'edit', checked: boolean) => {
    setControllerPermission((prevData) =>
      prevData.map((item) => {
        if (item.controllerId !== id) {
          return item;
        }

        let newRole: Role | null = null;

        if (clickType === 'view') {
          if (checked) {
            newRole = Role.VIEW;
            /* If View checkbox is being unchecked, Edit checkbox will be unchecked also.
             ==> No set new role for case: uncheck View checkbox (Use newRole as null) */
          }
        } else if (checked) {
          newRole = Role.EDIT;
        } else {
          /* Role.EDIT requires Role.VIEW enabled.
          So, uncheck Edit checkbox -> uncheck View checkbox also*/
          newRole = item.role === Role.VIEW ? Role.VIEW : null;
        }

        return {
          ...item,
          role: newRole,
        };
      }),
    );
  }, []);

  const handleSelectAll = useCallback((permissionType: 'view' | 'edit', selectAll: boolean) => {
    setControllerPermission((prevData) =>
      prevData.map((item) => {
        let newRole: Role | null = null;

        if (permissionType === 'view') {
          newRole = selectAll ? Role.VIEW : null;
        } else if (permissionType === 'edit') {
          const roleViewOrNull = item.role === Role.VIEW ? Role.VIEW : null;
          newRole = selectAll ? Role.EDIT : roleViewOrNull;
        }

        return {
          ...item,
          role: newRole,
        };
      }),
    );
  }, []);

  const isSelectAllViewChecked = useMemo(() => {
    return controllerPermission.every((item) => item.role === Role.VIEW || item.role === Role.EDIT);
  }, [controllerPermission]);

  const isSelectAllEditChecked = useMemo(() => {
    return controllerPermission.every((item) => item.role === Role.EDIT);
  }, [controllerPermission]);

  const columns = useMemo(
    () => tableConfig.columns(handleCheckboxChange, handleSelectAll, isSelectAllViewChecked, isSelectAllEditChecked),
    [handleCheckboxChange, handleSelectAll, isSelectAllViewChecked, isSelectAllEditChecked],
  );

  const handleUpdatePermissions = useCallback(() => {
    const permissionEntities: FPermission[] = controllerPermission.map((permission) => {
      return {
        id: permission.id,
        titleId: permission.titleId,
        controllerId: permission.controllerId,
        role: permission.role,
      };
    });
    updateControllerPermissionAsync({ data: permissionEntities })
      .then(() => {
        toast.success(
          <div>
            <b>Thành công</b> <br />
            Đã cập nhật phân quyền chức năng cho Chức danh.
          </div>,
        );
        refetchPermission();
      })
      .catch((error) => {
        toast.error(
          <div>
            <b>Thất bại</b> <br />
            {error?.response?.data?.error?.message || error.message}
          </div>,
        );
        refetchPermission();
      });
  }, [controllerPermission, refetchPermission, updateControllerPermissionAsync]);

  const handleUpdateTitle = useCallback(
    (id: number, updatedTitle: FTitle) => {
      if (id === null || id === undefined || id !== selectedTitle?.id) {
        return toast.error('<b>Thất bại</b> <br> Dữ liệu cập nhật Chức danh không hợp lệ');
      }
      updateTitleAsync({ id, data: updatedTitle })
        .then(() => {
          toast.success(
            <div>
              <b>Thành công</b> <br /> Đã cập nhật thông tin Chức danh: {updatedTitle.name}
            </div>,
          );
          refetchListTitle();
          refetchPermission();
          setOpenTitleModal(false);
        })
        .catch(() => {
          toast.error(
            <div>
              <b>Thất bại </b> <br /> Đã có lỗi xảy ra, cập nhật thông tin Chức danh thất bại.
            </div>,
          );
        });
    },
    [refetchListTitle, refetchPermission, selectedTitle?.id, updateTitleAsync],
  );

  const handleAddNewTitle = useCallback(
    (newTitle: NewFTitle) => {
      createTitleAsync({ data: newTitle })
        .then(() => {
          toast.success(
            <div>
              <b>Thành công</b> <br />
              Tạo mới thành công Chức danh <b>{newTitle.name}</b>.
            </div>,
          );
          refetchListTitle();
          refetchPermission();
          setOpenTitleModal(false);
        })
        .catch((error) => {
          toast.error(
            <div>
              <b>Thất bại</b> <br />
              {error?.response?.data?.error?.message || error.message}
            </div>,
          );
        });
    },
    [createTitleAsync, refetchPermission, refetchListTitle],
  );

  const getWarehouseCountPermissionMessage = useCallback(() => {
    if (titlePermissionData) {
      const noLimitKhoVtMessageElement = (
        <>
          Được quyền thao tác trên <span className="text-red">tất cả</span> Kho Vật tư
        </>
      );
      const noLimitKhoTpMessageElement = (
        <>
          Được quyền thao tác trên <span className="text-red">tất cả</span> Kho Thành phẩm
        </>
      );

      return (
        <>
          <small className="font-italic">
            {titlePermissionData.dsKhoVtPermission ? (
              <>
                Số lượng Kho Vật tư đang được giới hạn:{' '}
                <span className="text-red font-weight-bold">
                  {titlePermissionData.dsKhoVtPermission.split(',').length}
                </span>
              </>
            ) : (
              noLimitKhoVtMessageElement
            )}
          </small>
          <br />
          <small className="font-italic">
            {titlePermissionData.dsKhoTpPermission ? (
              <>
                Số lượng Kho Thành phẩm đang được giới hạn:{' '}
                <span className="text-red font-weight-bold">
                  {titlePermissionData.dsKhoTpPermission.split(',').length}
                </span>
              </>
            ) : (
              noLimitKhoTpMessageElement
            )}
          </small>
        </>
      );
    }
  }, [titlePermissionData]);

  return (
    <>
      <BasicTemplate pageTitle={Page.TITLE_MANAGEMENT.TITLE} pageName={Page.TITLE_MANAGEMENT.NAME}>
        <Container className="mt--6" fluid>
          <Row key="users">
            <div className="col">
              <Card>
                <CardHeader>
                  <h3>QUẢN LÝ PERMISSION</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="itemSelect">Chức danh</Label>
                      <Select2Box
                        className="form-control"
                        options={{ placeholder: 'Chọn Chức danh' }}
                        value={selectedTitleId}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedTitleId(e.target.value)}
                        data={titleOptions}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-end mt-5">
                    <Col className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setIsTitleModalCreatedMode(false);
                          setOpenTitleModal(true);
                        }}
                        disabled={!selectedTitleId}>
                        Cập nhật
                      </Button>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setIsTitleModalCreatedMode(true);
                          setOpenTitleModal(true);
                        }}>
                        Thêm mới
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mx-2 mt-5">
                    <Col>
                      <h4 className=""> Phân quyền theo Kho</h4>
                      {getWarehouseCountPermissionMessage()}
                    </Col>
                    <Col md={3} className="align-content-center">
                      <Button
                        color="primary"
                        onClick={() => {
                          setIsTitleModalCreatedMode(false);
                          setOpenTitleModal(true);
                        }}
                        disabled={!selectedTitleId}>
                        Phân quyền kho
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Card>
                        <Row className="mx-2 mt-4">
                          <Col>
                            <h4 className=""> Phân quyền theo Chức năng</h4>
                            <small className="font-italic">Thay đổi quyền hạn theo chức năng ở bảng bên dưới</small>
                          </Col>
                          <Col md={3} className="align-content-center">
                            <Button
                              color="primary"
                              onClick={handleUpdatePermissions}
                              disabled={isUpdating || !selectedTitleId}>
                              {!isUpdating ? 'Lưu bảng phân quyền' : 'Đang lưu'}
                            </Button>
                          </Col>
                        </Row>
                        <ToolkitProvider data={controllerPermission} keyField="controllerName" columns={columns}>
                          {(props: any) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                keyField="controllerName"
                                remote
                                hover
                                bordered={false}
                                noDataIndication={() => (
                                  <div className="text-center">
                                    {isLoading ? (
                                      <BasicSpinner />
                                    ) : (
                                      'Chưa chọn chức danh hoặc không có dữ liệu chức năng cho chức danh đã chọn'
                                    )}
                                  </div>
                                )}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </BasicTemplate>
      {openTitleModal && (
        <TitleModal
          isOpen={openTitleModal}
          selectedTitle={isTitleModalCreatedMode ? undefined : selectedTitle}
          handleEdit={handleUpdateTitle}
          handleAdd={handleAddNewTitle}
          isUpdatePending={isUpdatePending}
          isAddPending={isAddPending}
          toggle={() => setOpenTitleModal(!openTitleModal)}
        />
      )}
    </>
  );
};

export default TitlePermissionManagement;
