const Error = {
  SYSTEM_ERROR: {
    HEADING: 'Đã xảy ra lỗi hệ thống',
    MESSAGE:
      'Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng đảm bảo chỉ định đúng URL, v.v... hoặc vui lòng thử lại sau.',
  },
  URL_NOT_FOUND: {
    HEADING: 'Không tìm thấy trang bạn đang tìm kiếm',
    MESSAGE: 'Trang bạn đang tìm kiếm có thể tạm thời không khả dụng hoặc đã bị di chuyển hoặc xóa.',
  },

  ACCESS_DENIED: {
    HEADING: 'Bạn bị từ chối truy cập',
    MESSAGE: 'Bạn không có quyền để truy cập vào trang này.',
  },
};

export default Error;
