import moment from 'moment';

export const cellDateFormatter = (format: string) => {
  return (cell: string, _: any) => {
    if (!cell) {
      return null;
    }

    // Check if UTC time
    const date = cell.includes('T') ? moment(cell).utc() : moment(cell);

    return <div>{date.format(format)}</div>;
  };
};
