import {
  useKhovtTransPhieuNhapControllerCreate,
  useKhovtTransPhieuNhapControllerDeleteById,
  useKhovtTransPhieuNhapControllerUpdateById,
} from 'main/apis/drc/endpoints/khovt-trans-phieu-nhap-controller/khovt-trans-phieu-nhap-controller';
import { KhovtTransPhieuNhapControllerFindDetail200 } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { ToastMessage } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  QuyDinhHuongDanMasterTaiLieu,
  selectDocumentTable,
  updateDocumentTable,
} from 'main/redux/modules/terms-and-conditions/terms-and-conditions-list-slice';
import { generateColumnStyle } from 'main/utils';
import moment from 'moment';
import { useCallback, useState } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import AddEditDocumentModal from './add-edit-document-modal';
import DeleteDocumentModal from './delete-document-modal';
import tableConfig from './table-config';
import ViewDocumentModal from './view-document-modal';

type Props = {
  data?: KhovtTransPhieuNhapControllerFindDetail200;
  dataIsLoading: boolean;
  handleRefreshTable: () => void;
};

function TermsAndConditionsTable({
  data: { data, count: dataCount } = { data: [], count: 0 },
  dataIsLoading,
  handleRefreshTable,
}: Props) {
  // Redux
  const dispatch = useAppDispatch();
  const { page, sizePerPage, sortField, sortOrder } = useAppSelector(selectDocumentTable);

  // React State/Ref
  const [isOpenViewModal, setOpenViewModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenAddEditModal, setOpenAddEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<QuyDinhHuongDanMasterTaiLieu>();

  // React Query
  const { mutateAsync: deletedDocumentMutateAsync } = useKhovtTransPhieuNhapControllerDeleteById();
  const { mutateAsync: updatedDocumentMutateAsync } = useKhovtTransPhieuNhapControllerUpdateById();
  const { mutateAsync: createdDocumentMutateAsync } = useKhovtTransPhieuNhapControllerCreate();

  // React Function
  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<QuyDinhHuongDanMasterTaiLieu>) => {
      const { page, sizePerPage, sortField, sortOrder } = newState;

      dispatch(
        updateDocumentTable({
          page,
          sizePerPage,
          sortField,
          sortOrder,
        }),
      );
    },
    [dispatch],
  );

  const toggleViewModal = useCallback(() => setOpenViewModal((isOpenViewModal) => !isOpenViewModal), []);

  const toggleDeleteModal = useCallback(() => setOpenDeleteModal((isOpenDeleteModal) => !isOpenDeleteModal), []);

  const toggleAddEditModal = useCallback(() => setOpenAddEditModal((isOpenAddEditModal) => !isOpenAddEditModal), []);

  const handleViewActionClick = useCallback(
    (row: QuyDinhHuongDanMasterTaiLieu) => {
      setSelectedRow(row);
      toggleViewModal();
    },
    [toggleViewModal],
  );

  const handleDeleteActionClick = useCallback(
    (row: QuyDinhHuongDanMasterTaiLieu) => {
      setSelectedRow(row);
      toggleDeleteModal();
    },
    [toggleDeleteModal],
  );

  const handleEditActionClick = useCallback(
    (row: QuyDinhHuongDanMasterTaiLieu) => {
      setSelectedRow(row);
      toggleAddEditModal();
    },
    [toggleAddEditModal],
  );

  const handleAddButtonClick = useCallback(() => {
    setSelectedRow(undefined);
    toggleAddEditModal();
  }, [toggleAddEditModal]);

  const handleDeleteModalConfirmClick = useCallback(() => {
    // Close modal
    toggleDeleteModal();

    // Delete document
    deletedDocumentMutateAsync({ id: Number(selectedRow?.maTaiLieu) })
      .then((_) => {
        // Refresh table data
        handleRefreshTable();
        toast.success(ToastMessage.CANCEL_SUCCESS);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  }, [deletedDocumentMutateAsync, handleRefreshTable, selectedRow?.maTaiLieu, toggleDeleteModal]);

  const handleAddEditModalConfirmClick = useCallback(
    (values: QuyDinhHuongDanMasterTaiLieu) => {
      // Close modal
      toggleAddEditModal();

      const { id, ...data } = values;
      if (id) {
        // Edit document
        updatedDocumentMutateAsync({
          id: Number(id),
          data,
        })
          .then((_) => {
            // Refresh table data
            handleRefreshTable();
            toast.success(ToastMessage.UPDATE_SUCCESS);
          })
          .catch((e) => {
            toast.error(e?.response?.data?.error?.message || e.message);
          });
      } else {
        // Add document
        createdDocumentMutateAsync({
          data,
        })
          .then((_) => {
            // Refresh table data
            handleRefreshTable();
            toast.success(ToastMessage.CREATE_SUCCESS);
          })
          .catch((e) => {
            toast.error(e?.response?.data?.error?.message || e.message);
          });
      }
    },
    [createdDocumentMutateAsync, handleRefreshTable, toggleAddEditModal, updatedDocumentMutateAsync],
  );

  return (
    <ToolkitProvider
      data={[
        {
          id: 900,
          maTaiLieu: 'TL20240821-900',
          tenTaiLieu: 'Tài liệu PDF test 1',
          urlTaiLieu: 'https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 901,
          maTaiLieu: 'TL20240821-901',
          tenTaiLieu: 'Tài liệu PDF test 2',
          urlTaiLieu: 'https://pdfobject.com/pdf/sample.pdf',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 902,
          maTaiLieu: 'TL20240821-902',
          tenTaiLieu: 'Tài liệu PDF test 3',
          urlTaiLieu: require('./docs/c4611_sample_explain.pdf'),
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 903,
          maTaiLieu: 'TL20240821-903',
          tenTaiLieu: 'Tài liệu Doc test 1',
          urlTaiLieu: require('./docs/file-sample_100kB.docx'),
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 904,
          maTaiLieu: 'TL20240821-904',
          tenTaiLieu: 'Tài liệu Doc test 2',
          urlTaiLieu: 'https://docproc.bsite.net/Doc1.docx',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 905,
          maTaiLieu: 'TL20240821-905',
          tenTaiLieu: 'Tài liệu Excel test 1',
          urlTaiLieu: 'https://www.w3resource.com/python-exercises/pandas/excel/SaleData.xlsx',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 906,
          maTaiLieu: 'TL20240821-906',
          tenTaiLieu: 'Tài liệu Excel test 2',
          urlTaiLieu: 'https://file-examples.com/wp-content/storage/2017/02/file_example_XLSX_50.xlsx',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 907,
          maTaiLieu: 'TL20240821-907',
          tenTaiLieu: 'Tài liệu Excel test 3',
          urlTaiLieu: require('./docs/SRS_Định mức và danh mục BTP.xlsx'),
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 908,
          maTaiLieu: 'TL20240821-908',
          tenTaiLieu: 'Tài liệu Image test 1',
          urlTaiLieu:
            'https://fastly.picsum.photos/id/949/200/200.jpg?hmac=olyRqIDYyw9CmKd4lDibZ72sy7THVBmfiSvM9z8CkKY',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
        {
          id: 909,
          maTaiLieu: 'TL20240821-909',
          tenTaiLieu: 'Tài liệu Image test 2',
          urlTaiLieu:
            'https://fastly.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U',
          ngayBanHanh: moment().toISOString(),
          lanBanHanh: '1.0.1',
          nguoiBanHanh: 'ToanVH',
          nguoiThucHien: 'ToanVH1999',
        },
      ]}
      keyField={`${FieldName.ID}`}
      columns={[
        tableConfig.columns[0],
        {
          dataField: FieldName.TEN_TAI_LIEU,
          text: 'Tên tài liệu',
          sort: true,
          ...generateColumnStyle(),
          formatter: (cell: string, row: QuyDinhHuongDanMasterTaiLieu) => {
            return (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewActionClick(row);
                }}>
                {cell}
              </Link>
            );
          },
        },
        ...tableConfig.columns.slice(1),
        {
          dataField: FieldName.ACTION,
          isDummyField: true,
          text: 'Thao tác',
          ...generateColumnStyle(),
          formatter: (_: any, row: QuyDinhHuongDanMasterTaiLieu) => {
            return (
              <div className="d-flex">
                <Link
                  className="px-2"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteActionClick(row);
                  }}>
                  <i className="fa-solid fa-trash" title="Xóa" />
                </Link>
                <Link
                  className="px-2"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditActionClick(row);
                  }}>
                  <i className="fa-solid fa-pen-to-square" title="Sửa" />
                </Link>
              </div>
            );
          },
        },
      ]}>
      {(props: any) => (
        <>
          <div className="d-flex justify-content-end mb-4 pb-1">
            <Button color="primary" onClick={handleAddButtonClick}>
              Thêm mới
            </Button>
          </div>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              sort={
                sortField
                  ? {
                      dataField: sortField,
                      order: sortOrder,
                    }
                  : {}
              }
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
          <ViewDocumentModal document={selectedRow} isOpen={isOpenViewModal} toggle={toggleViewModal} />
          <DeleteDocumentModal
            document={selectedRow}
            isOpen={isOpenDeleteModal}
            toggle={toggleDeleteModal}
            handleConfirmClick={handleDeleteModalConfirmClick}
          />
          <AddEditDocumentModal
            document={selectedRow}
            isOpen={isOpenAddEditModal}
            toggle={toggleAddEditModal}
            handleConfirmClick={handleAddEditModalConfirmClick}
          />
        </>
      )}
    </ToolkitProvider>
  );
}

export default TermsAndConditionsTable;
