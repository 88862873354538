/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  FTitle,
  FTitleControllerCountParams,
  FTitleControllerFindParams,
  FTitlePartial,
  FTitleWithPermissionWithControllerNameSchema,
  LoopbackCount,
  NewFTitle
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const fTitleControllerCount = (
    params?: FTitleControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/titles/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFTitleControllerCountQueryKey = (params?: FTitleControllerCountParams,) => {
    return [`/titles/count`, ...(params ? [params]: [])] as const;
    }

    
export const getFTitleControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof fTitleControllerCount>>, TError = ErrorType<unknown>>(params?: FTitleControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFTitleControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fTitleControllerCount>>> = ({ signal }) => fTitleControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type FTitleControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerCount>>>
export type FTitleControllerCountQueryError = ErrorType<unknown>

export const useFTitleControllerCount = <TData = Awaited<ReturnType<typeof fTitleControllerCount>>, TError = ErrorType<unknown>>(
 params?: FTitleControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFTitleControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const fTitleControllerFindWithPermissionsById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<FTitleWithPermissionWithControllerNameSchema>(
      {url: `/titles/${id}/permissons`, method: 'GET', signal
    },
      );
    }
  

export const getFTitleControllerFindWithPermissionsByIdQueryKey = (id: number,) => {
    return [`/titles/${id}/permissons`] as const;
    }

    
export const getFTitleControllerFindWithPermissionsByIdQueryOptions = <TData = Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFTitleControllerFindWithPermissionsByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>> = ({ signal }) => fTitleControllerFindWithPermissionsById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>, TError, TData> & { queryKey: QueryKey }
}

export type FTitleControllerFindWithPermissionsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>>
export type FTitleControllerFindWithPermissionsByIdQueryError = ErrorType<unknown>

export const useFTitleControllerFindWithPermissionsById = <TData = Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindWithPermissionsById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFTitleControllerFindWithPermissionsByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const fTitleControllerUpdateById = (
    id: number,
    fTitlePartial: FTitlePartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/titles/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: fTitlePartial
    },
      );
    }
  


export const getFTitleControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerUpdateById>>, TError,{id: number;data: FTitlePartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerUpdateById>>, TError,{id: number;data: FTitlePartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fTitleControllerUpdateById>>, {id: number;data: FTitlePartial}> = (props) => {
          const {id,data} = props ?? {};

          return  fTitleControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FTitleControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerUpdateById>>>
    export type FTitleControllerUpdateByIdMutationBody = FTitlePartial
    export type FTitleControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useFTitleControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerUpdateById>>, TError,{id: number;data: FTitlePartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fTitleControllerUpdateById>>,
        TError,
        {id: number;data: FTitlePartial},
        TContext
      > => {

      const mutationOptions = getFTitleControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fTitleControllerFindById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<FTitle>(
      {url: `/titles/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getFTitleControllerFindByIdQueryKey = (id: number,) => {
    return [`/titles/${id}`] as const;
    }

    
export const getFTitleControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof fTitleControllerFindById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFTitleControllerFindByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fTitleControllerFindById>>> = ({ signal }) => fTitleControllerFindById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type FTitleControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerFindById>>>
export type FTitleControllerFindByIdQueryError = ErrorType<unknown>

export const useFTitleControllerFindById = <TData = Awaited<ReturnType<typeof fTitleControllerFindById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFTitleControllerFindByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const fTitleControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/titles/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getFTitleControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fTitleControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  fTitleControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FTitleControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerDeleteById>>>
    
    export type FTitleControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useFTitleControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fTitleControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getFTitleControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fTitleControllerCreate = (
    newFTitle: NewFTitle,
 ) => {
      
      
      return drcInstance<FTitle>(
      {url: `/titles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newFTitle
    },
      );
    }
  


export const getFTitleControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerCreate>>, TError,{data: NewFTitle}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerCreate>>, TError,{data: NewFTitle}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fTitleControllerCreate>>, {data: NewFTitle}> = (props) => {
          const {data} = props ?? {};

          return  fTitleControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FTitleControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerCreate>>>
    export type FTitleControllerCreateMutationBody = NewFTitle
    export type FTitleControllerCreateMutationError = ErrorType<unknown>

    export const useFTitleControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fTitleControllerCreate>>, TError,{data: NewFTitle}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fTitleControllerCreate>>,
        TError,
        {data: NewFTitle},
        TContext
      > => {

      const mutationOptions = getFTitleControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fTitleControllerFind = (
    params?: FTitleControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<FTitle[]>(
      {url: `/titles`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFTitleControllerFindQueryKey = (params?: FTitleControllerFindParams,) => {
    return [`/titles`, ...(params ? [params]: [])] as const;
    }

    
export const getFTitleControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof fTitleControllerFind>>, TError = ErrorType<unknown>>(params?: FTitleControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFTitleControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fTitleControllerFind>>> = ({ signal }) => fTitleControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type FTitleControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof fTitleControllerFind>>>
export type FTitleControllerFindQueryError = ErrorType<unknown>

export const useFTitleControllerFind = <TData = Awaited<ReturnType<typeof fTitleControllerFind>>, TError = ErrorType<unknown>>(
 params?: FTitleControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fTitleControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFTitleControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



