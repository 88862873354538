import { useKhotpMasterKhoControllerFind } from 'main/apis/drc/endpoints/khotp-master-kho-controller/khotp-master-kho-controller';
import { useMemo } from 'react';

function useProductStoreOptions(includeAllOption?: boolean) {
  // React Query
  const { data: productStores, isLoading: productStoresIsLoading } = useKhotpMasterKhoControllerFind();

  // React Function
  const productStoreOptions = useMemo(() => {
    const options = [];

    if (includeAllOption) {
      options.push({ id: '-1', text: 'Tất cả' });
    }

    if (productStores) {
      options.push(...productStores.map((productStore) => ({ id: productStore.maKho, text: productStore.tenKho })));
    }

    return options;
  }, [includeAllOption, productStores]);

  return { productStoreOptions, productStoresIsLoading };
}

export default useProductStoreOptions;
