import { KhotpXuatNhapTonControllerSearchCanhBao200Item } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { FieldName } from 'main/constants/enums';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import tableConfig from './table-config';

type Props = {
  data?: KhotpXuatNhapTonControllerSearchCanhBao200Item[];
  dataIsLoading: boolean;
};

function ProductStoreInventoryWarningTable({ data, dataIsLoading }: Props) {
  return (
    <ToolkitProvider
      data={data || []}
      keyField={`${FieldName.SO_PHIEU_NHAP}_${FieldName.MA_SAN_PHAM}_${FieldName.SO_LO}`}
      columns={tableConfig.columns}>
      {(props: any) => (
        <>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
}

export default ProductStoreInventoryWarningTable;
