/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterNhomSpControllerCountParams,
  KhotpMasterNhomSpControllerFindByIdParams,
  KhotpMasterNhomSpControllerFindParams,
  KhotpMasterNhomSpControllerUpdateAllParams,
  KhotpMasterNhomsp,
  KhotpMasterNhomspPartial,
  KhotpMasterNhomspWithRelations,
  LoopbackCount,
  NewKhotpMasterNhomsp
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterNhomSpControllerCount = (
    params?: KhotpMasterNhomSpControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-nhomsps/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomSpControllerCountQueryKey = (params?: KhotpMasterNhomSpControllerCountParams,) => {
    return [`/khotp-master-nhomsps/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomSpControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterNhomSpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomSpControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>> = ({ signal }) => khotpMasterNhomSpControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomSpControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>>
export type KhotpMasterNhomSpControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterNhomSpControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterNhomSpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomSpControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterNhomSpControllerReplaceById = (
    id: string,
    khotpMasterNhomsp: KhotpMasterNhomsp,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhomsps/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterNhomsp
    },
      );
    }
  


export const getKhotpMasterNhomSpControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>, {id: string;data: KhotpMasterNhomsp}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterNhomSpControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomSpControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>>
    export type KhotpMasterNhomSpControllerReplaceByIdMutationBody = KhotpMasterNhomsp
    export type KhotpMasterNhomSpControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomSpControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomSpControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterNhomsp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomSpControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomSpControllerUpdateById = (
    id: string,
    khotpMasterNhomspPartial: KhotpMasterNhomspPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhomsps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterNhomspPartial
    },
      );
    }
  


export const getKhotpMasterNhomSpControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>, {id: string;data: KhotpMasterNhomspPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterNhomSpControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomSpControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>>
    export type KhotpMasterNhomSpControllerUpdateByIdMutationBody = KhotpMasterNhomspPartial
    export type KhotpMasterNhomSpControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomSpControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterNhomspPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomSpControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomSpControllerFindById = (
    id: string,
    params?: KhotpMasterNhomSpControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterNhomspWithRelations>(
      {url: `/khotp-master-nhomsps/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomSpControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterNhomSpControllerFindByIdParams,) => {
    return [`/khotp-master-nhomsps/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomSpControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterNhomSpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomSpControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>> = ({ signal }) => khotpMasterNhomSpControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomSpControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>>
export type KhotpMasterNhomSpControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterNhomSpControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterNhomSpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomSpControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterNhomSpControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhomsps/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterNhomSpControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterNhomSpControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomSpControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>>
    
    export type KhotpMasterNhomSpControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomSpControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomSpControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomSpControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomSpControllerCreate = (
    newKhotpMasterNhomsp: NewKhotpMasterNhomsp,
 ) => {
      
      
      return drcInstance<KhotpMasterNhomsp>(
      {url: `/khotp-master-nhomsps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterNhomsp
    },
      );
    }
  


export const getKhotpMasterNhomSpControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>, {data: NewKhotpMasterNhomsp}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterNhomSpControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomSpControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>>
    export type KhotpMasterNhomSpControllerCreateMutationBody = NewKhotpMasterNhomsp
    export type KhotpMasterNhomSpControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomSpControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomSpControllerCreate>>,
        TError,
        {data: NewKhotpMasterNhomsp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomSpControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomSpControllerUpdateAll = (
    khotpMasterNhomspPartial: KhotpMasterNhomspPartial,
    params?: KhotpMasterNhomSpControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-nhomsps`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterNhomspPartial,
        params
    },
      );
    }
  


export const getKhotpMasterNhomSpControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>, TError,{data: KhotpMasterNhomspPartial;params?: KhotpMasterNhomSpControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>, TError,{data: KhotpMasterNhomspPartial;params?: KhotpMasterNhomSpControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>, {data: KhotpMasterNhomspPartial;params?: KhotpMasterNhomSpControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterNhomSpControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomSpControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>>
    export type KhotpMasterNhomSpControllerUpdateAllMutationBody = KhotpMasterNhomspPartial
    export type KhotpMasterNhomSpControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomSpControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>, TError,{data: KhotpMasterNhomspPartial;params?: KhotpMasterNhomSpControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomSpControllerUpdateAll>>,
        TError,
        {data: KhotpMasterNhomspPartial;params?: KhotpMasterNhomSpControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomSpControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomSpControllerFind = (
    params?: KhotpMasterNhomSpControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterNhomspWithRelations[]>(
      {url: `/khotp-master-nhomsps`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomSpControllerFindQueryKey = (params?: KhotpMasterNhomSpControllerFindParams,) => {
    return [`/khotp-master-nhomsps`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomSpControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterNhomSpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomSpControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>> = ({ signal }) => khotpMasterNhomSpControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomSpControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>>
export type KhotpMasterNhomSpControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterNhomSpControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterNhomSpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomSpControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomSpControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



