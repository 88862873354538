import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import { Card, CardBody, CardHeader, Container, Label, Row } from 'reactstrap';
import CancelExportBillModal from './sub-components/cancel-export-bill-modal';
import ProductStoreExportFilter from './sub-components/product-store-export-filter';
import ProductStoreExportTable from './sub-components/product-store-export-table/product-store-export-table';
import useHandleProductStoreExportList from './useHandleProductStoreExportList';

const ProductStoreExportList = () => {
  const {
    deletedProductExportBillIsPending,
    filter,
    handleCancelBillPopup,
    handleCancelModalConfirmClick,
    handleDateChange,
    handleSearchClick,
    handleTableChange,
    isOpenCancelModal,
    productExportBillId,
    productExportBills,
    productExportBillsCount,
    productExportBillsIsLoading,
    table,
    toggleCancelModal,
  } = useHandleProductStoreExportList();
  return (
    <BasicTemplate pageTitle={Page.PRODUCT_STORE_EXPORT_LIST.TITLE} pageName={Page.PRODUCT_STORE_EXPORT_LIST.NAME}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>TỔNG HỢP XUẤT SẢN PHẨM</h3>
              </CardHeader>
              <CardBody>
                <Label className="form-control-label">Kỳ báo cáo</Label>
                <ProductStoreExportFilter
                  filter={filter}
                  handleSearchClick={handleSearchClick}
                  handleDateChange={handleDateChange}
                />
                <hr />
                <ProductStoreExportTable
                  data={productExportBills}
                  dataIsLoading={productExportBillsIsLoading}
                  deletedLoading={deletedProductExportBillIsPending}
                  dataCount={productExportBillsCount}
                  tableState={table}
                  handleTableStateChange={handleTableChange}
                  toggleCancelModal={toggleCancelModal}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <CancelExportBillModal
        productExportBillId={productExportBillId}
        isOpen={isOpenCancelModal}
        toggle={handleCancelBillPopup}
        handleConfirmClick={handleCancelModalConfirmClick}
      />
    </BasicTemplate>
  );
};

export default ProductStoreExportList;
