import { FieldName } from 'main/constants/enums';

export const AuthenticationUtil = {
  getAuthToken() {
    return localStorage.getItem(FieldName.AUTH_TOKEN_KEY);
  },

  setAuthToken(token: string) {
    return localStorage.setItem(FieldName.AUTH_TOKEN_KEY, token);
  },

  deleteAuthToken() {
    return localStorage.removeItem(FieldName.AUTH_TOKEN_KEY);
  },

  getUsername() {
    return localStorage.getItem(FieldName.USERNAME_KEY);
  },

  setUsername(username: string) {
    return localStorage.setItem(FieldName.USERNAME_KEY, username);
  },

  deleteUsername() {
    return localStorage.removeItem(FieldName.USERNAME_KEY);
  },
};
