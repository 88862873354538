/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhoMasterNuoc,
  KhovtMasterNguonGoc,
  KhovtMasterNguonGocControllerCountParams,
  KhovtMasterNguonGocControllerFindByIdParams,
  KhovtMasterNguonGocControllerFindNuocParams,
  KhovtMasterNguonGocControllerFindParams,
  KhovtMasterNguonGocPartial,
  KhovtMasterNguonGocWithRelations,
  LoopbackCount,
  NewKhovtMasterNguonGoc
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtMasterNguonGocControllerCount = (
    params?: KhovtMasterNguonGocControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khovt-master-nguon-gocs/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterNguonGocControllerCountQueryKey = (params?: KhovtMasterNguonGocControllerCountParams,) => {
    return [`/khovt-master-nguon-gocs/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterNguonGocControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>, TError = ErrorType<unknown>>(params?: KhovtMasterNguonGocControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterNguonGocControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>> = ({ signal }) => khovtMasterNguonGocControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterNguonGocControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>>
export type KhovtMasterNguonGocControllerCountQueryError = ErrorType<unknown>

export const useKhovtMasterNguonGocControllerCount = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterNguonGocControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterNguonGocControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterNguonGocControllerFindNuoc = (
    params?: KhovtMasterNguonGocControllerFindNuocParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhoMasterNuoc[]>(
      {url: `/khovt-master-nguon-gocs/nuoc`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterNguonGocControllerFindNuocQueryKey = (params?: KhovtMasterNguonGocControllerFindNuocParams,) => {
    return [`/khovt-master-nguon-gocs/nuoc`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterNguonGocControllerFindNuocQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>, TError = ErrorType<unknown>>(params?: KhovtMasterNguonGocControllerFindNuocParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterNguonGocControllerFindNuocQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>> = ({ signal }) => khovtMasterNguonGocControllerFindNuoc(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterNguonGocControllerFindNuocQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>>
export type KhovtMasterNguonGocControllerFindNuocQueryError = ErrorType<unknown>

export const useKhovtMasterNguonGocControllerFindNuoc = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterNguonGocControllerFindNuocParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindNuoc>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterNguonGocControllerFindNuocQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterNguonGocControllerUpdateById = (
    id: number,
    khovtMasterNguonGocPartial: KhovtMasterNguonGocPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-nguon-gocs/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khovtMasterNguonGocPartial
    },
      );
    }
  


export const getKhovtMasterNguonGocControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>, TError,{id: number;data: KhovtMasterNguonGocPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>, TError,{id: number;data: KhovtMasterNguonGocPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>, {id: number;data: KhovtMasterNguonGocPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khovtMasterNguonGocControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterNguonGocControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>>
    export type KhovtMasterNguonGocControllerUpdateByIdMutationBody = KhovtMasterNguonGocPartial
    export type KhovtMasterNguonGocControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhovtMasterNguonGocControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>, TError,{id: number;data: KhovtMasterNguonGocPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterNguonGocControllerUpdateById>>,
        TError,
        {id: number;data: KhovtMasterNguonGocPartial},
        TContext
      > => {

      const mutationOptions = getKhovtMasterNguonGocControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterNguonGocControllerFindById = (
    id: number,
    params?: KhovtMasterNguonGocControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterNguonGocWithRelations>(
      {url: `/khovt-master-nguon-gocs/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterNguonGocControllerFindByIdQueryKey = (id: number,
    params?: KhovtMasterNguonGocControllerFindByIdParams,) => {
    return [`/khovt-master-nguon-gocs/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterNguonGocControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: KhovtMasterNguonGocControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterNguonGocControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>> = ({ signal }) => khovtMasterNguonGocControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterNguonGocControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>>
export type KhovtMasterNguonGocControllerFindByIdQueryError = ErrorType<unknown>

export const useKhovtMasterNguonGocControllerFindById = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhovtMasterNguonGocControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterNguonGocControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterNguonGocControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-nguon-gocs/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhovtMasterNguonGocControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khovtMasterNguonGocControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterNguonGocControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>>
    
    export type KhovtMasterNguonGocControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhovtMasterNguonGocControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterNguonGocControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhovtMasterNguonGocControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterNguonGocControllerCreate = (
    newKhovtMasterNguonGoc: NewKhovtMasterNguonGoc,
 ) => {
      
      
      return drcInstance<KhovtMasterNguonGoc>(
      {url: `/khovt-master-nguon-gocs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhovtMasterNguonGoc
    },
      );
    }
  


export const getKhovtMasterNguonGocControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>, TError,{data: NewKhovtMasterNguonGoc}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>, TError,{data: NewKhovtMasterNguonGoc}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>, {data: NewKhovtMasterNguonGoc}> = (props) => {
          const {data} = props ?? {};

          return  khovtMasterNguonGocControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterNguonGocControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>>
    export type KhovtMasterNguonGocControllerCreateMutationBody = NewKhovtMasterNguonGoc
    export type KhovtMasterNguonGocControllerCreateMutationError = ErrorType<unknown>

    export const useKhovtMasterNguonGocControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>, TError,{data: NewKhovtMasterNguonGoc}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterNguonGocControllerCreate>>,
        TError,
        {data: NewKhovtMasterNguonGoc},
        TContext
      > => {

      const mutationOptions = getKhovtMasterNguonGocControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterNguonGocControllerFind = (
    params?: KhovtMasterNguonGocControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterNguonGocWithRelations[]>(
      {url: `/khovt-master-nguon-gocs`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterNguonGocControllerFindQueryKey = (params?: KhovtMasterNguonGocControllerFindParams,) => {
    return [`/khovt-master-nguon-gocs`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterNguonGocControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtMasterNguonGocControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterNguonGocControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>> = ({ signal }) => khovtMasterNguonGocControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterNguonGocControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>>
export type KhovtMasterNguonGocControllerFindQueryError = ErrorType<unknown>

export const useKhovtMasterNguonGocControllerFind = <TData = Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterNguonGocControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterNguonGocControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterNguonGocControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



