/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterDonVi,
  KhotpMasterDonViControllerCountParams,
  KhotpMasterDonViControllerFindByIdParams,
  KhotpMasterDonViControllerFindParams,
  KhotpMasterDonViControllerUpdateAllParams,
  KhotpMasterDonViPartial,
  KhotpMasterDonViWithRelations,
  LoopbackCount,
  NewKhotpMasterDonVi
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterDonViControllerCount = (
    params?: KhotpMasterDonViControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-don-vis/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterDonViControllerCountQueryKey = (params?: KhotpMasterDonViControllerCountParams,) => {
    return [`/khotp-master-don-vis/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterDonViControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterDonViControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterDonViControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>> = ({ signal }) => khotpMasterDonViControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterDonViControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>>
export type KhotpMasterDonViControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterDonViControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterDonViControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterDonViControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterDonViControllerReplaceById = (
    id: string,
    khotpMasterDonVi: KhotpMasterDonVi,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-don-vis/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterDonVi
    },
      );
    }
  


export const getKhotpMasterDonViControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>, TError,{id: string;data: KhotpMasterDonVi}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>, TError,{id: string;data: KhotpMasterDonVi}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>, {id: string;data: KhotpMasterDonVi}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterDonViControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterDonViControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>>
    export type KhotpMasterDonViControllerReplaceByIdMutationBody = KhotpMasterDonVi
    export type KhotpMasterDonViControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterDonViControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>, TError,{id: string;data: KhotpMasterDonVi}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterDonViControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterDonVi},
        TContext
      > => {

      const mutationOptions = getKhotpMasterDonViControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterDonViControllerUpdateById = (
    id: string,
    khotpMasterDonViPartial: KhotpMasterDonViPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-don-vis/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterDonViPartial
    },
      );
    }
  


export const getKhotpMasterDonViControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>, TError,{id: string;data: KhotpMasterDonViPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>, TError,{id: string;data: KhotpMasterDonViPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>, {id: string;data: KhotpMasterDonViPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterDonViControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterDonViControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>>
    export type KhotpMasterDonViControllerUpdateByIdMutationBody = KhotpMasterDonViPartial
    export type KhotpMasterDonViControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterDonViControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>, TError,{id: string;data: KhotpMasterDonViPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterDonViPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterDonViControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterDonViControllerFindById = (
    id: string,
    params?: KhotpMasterDonViControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterDonViWithRelations>(
      {url: `/khotp-master-don-vis/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterDonViControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterDonViControllerFindByIdParams,) => {
    return [`/khotp-master-don-vis/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterDonViControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterDonViControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterDonViControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>> = ({ signal }) => khotpMasterDonViControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterDonViControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>>
export type KhotpMasterDonViControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterDonViControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterDonViControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterDonViControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterDonViControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-don-vis/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterDonViControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterDonViControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterDonViControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>>
    
    export type KhotpMasterDonViControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterDonViControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterDonViControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterDonViControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterDonViControllerCreate = (
    newKhotpMasterDonVi: NewKhotpMasterDonVi,
 ) => {
      
      
      return drcInstance<KhotpMasterDonVi>(
      {url: `/khotp-master-don-vis`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterDonVi
    },
      );
    }
  


export const getKhotpMasterDonViControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>, TError,{data: NewKhotpMasterDonVi}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>, TError,{data: NewKhotpMasterDonVi}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>, {data: NewKhotpMasterDonVi}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterDonViControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterDonViControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>>
    export type KhotpMasterDonViControllerCreateMutationBody = NewKhotpMasterDonVi
    export type KhotpMasterDonViControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterDonViControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>, TError,{data: NewKhotpMasterDonVi}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterDonViControllerCreate>>,
        TError,
        {data: NewKhotpMasterDonVi},
        TContext
      > => {

      const mutationOptions = getKhotpMasterDonViControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterDonViControllerUpdateAll = (
    khotpMasterDonViPartial: KhotpMasterDonViPartial,
    params?: KhotpMasterDonViControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-don-vis`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterDonViPartial,
        params
    },
      );
    }
  


export const getKhotpMasterDonViControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>, TError,{data: KhotpMasterDonViPartial;params?: KhotpMasterDonViControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>, TError,{data: KhotpMasterDonViPartial;params?: KhotpMasterDonViControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>, {data: KhotpMasterDonViPartial;params?: KhotpMasterDonViControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterDonViControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterDonViControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>>
    export type KhotpMasterDonViControllerUpdateAllMutationBody = KhotpMasterDonViPartial
    export type KhotpMasterDonViControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterDonViControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>, TError,{data: KhotpMasterDonViPartial;params?: KhotpMasterDonViControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterDonViControllerUpdateAll>>,
        TError,
        {data: KhotpMasterDonViPartial;params?: KhotpMasterDonViControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterDonViControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterDonViControllerFind = (
    params?: KhotpMasterDonViControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterDonViWithRelations[]>(
      {url: `/khotp-master-don-vis`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterDonViControllerFindQueryKey = (params?: KhotpMasterDonViControllerFindParams,) => {
    return [`/khotp-master-don-vis`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterDonViControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterDonViControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterDonViControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>> = ({ signal }) => khotpMasterDonViControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterDonViControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>>
export type KhotpMasterDonViControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterDonViControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterDonViControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterDonViControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterDonViControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



