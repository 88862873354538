import { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { useKhovtMasterKhoControllerCount } from 'main/apis/drc/endpoints/khovt-master-kho-controller/khovt-master-kho-controller';
import { KhovtMasterKhoWithRelations } from 'main/apis/drc/models';

interface AddEditItemModalProps {
  isOpen: boolean;
  toggle: () => void;
  handleAdd: (item: { maKho: string; tenKho: string; ghiChu: string }) => void;
  handleEdit: (id: number, item: Partial<KhovtMasterKhoWithRelations>) => void;
  currentItem?: KhovtMasterKhoWithRelations | null;
}

const AddEditItemModal: React.FC<AddEditItemModalProps> = ({ isOpen, toggle, handleAdd, handleEdit, currentItem }) => {
  const [item, setItem] = useState({ maKho: '', tenKho: '', ghiChu: '' });
  const [errors, setErrors] = useState({ maKho: '', tenKho: '' });
  const [loading, setLoading] = useState(false);

  const { refetch: refetchCountMaKho } = useKhovtMasterKhoControllerCount(
    {
      where: { maKho: item.maKho.trim() },
    },
    {
      query: {
        enabled: false,
      },
    },
  );

  const { refetch: refetchCountTenKho } = useKhovtMasterKhoControllerCount(
    {
      where: { tenKho: item.tenKho.trim() },
    },
    {
      query: {
        enabled: false,
      },
    },
  );

  useEffect(() => {
    if (currentItem) {
      setItem({
        maKho: currentItem.maKho || '',
        tenKho: currentItem.tenKho || '',
        ghiChu: currentItem.ghiChu || '',
      });
    } else {
      resetForm();
    }
  }, [currentItem]);

  const resetForm = () => {
    setItem({ maKho: '', tenKho: '', ghiChu: '' });
    setErrors({ maKho: '', tenKho: '' });
  };

  const validate = async () => {
    const newErrors = { maKho: '', tenKho: '' };

    if (!item.maKho) {
      newErrors.maKho = 'Mã kho là trường bắt buộc';
    } else {
      const maKhoResponse = await refetchCountMaKho();
      if (
        maKhoResponse.data &&
        maKhoResponse.data.count !== undefined &&
        maKhoResponse.data.count > 0 &&
        (!currentItem || item.maKho !== currentItem.maKho)
      ) {
        newErrors.maKho = 'Mã kho đã tồn tại';
      }
    }

    if (!item.tenKho) {
      newErrors.tenKho = 'Tên Kho là trường bắt buộc';
    } else {
      const tenKhoResponse = await refetchCountTenKho();
      if (
        tenKhoResponse.data &&
        tenKhoResponse.data.count !== undefined &&
        tenKhoResponse.data.count > 0 &&
        (!currentItem || item.tenKho !== currentItem.tenKho)
      ) {
        newErrors.tenKho = 'Tên kho đã tồn tại';
      }
    }

    setErrors(newErrors);
    return !newErrors.maKho && !newErrors.tenKho;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const trimmedItem = {
      maKho: item.maKho.trim(),
      tenKho: item.tenKho.trim(),
      ghiChu: item.ghiChu.trim(),
    };
    setItem(trimmedItem);
    const isValid = await validate();
    if (isValid) {
      if (currentItem) {
        handleEdit(currentItem.id || 0, trimmedItem);
      } else {
        handleAdd(trimmedItem);
      }
      resetForm();
      toggle();
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={resetForm}>
      <ModalHeader toggle={toggle}>{currentItem === null ? 'Thêm Mới' : 'Chỉnh sửa'}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="maKho">
              Mã Kho <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Input
              type="text"
              name="maKho"
              id="maKho"
              value={item.maKho}
              onChange={handleChange}
              invalid={!!errors.maKho}
              readOnly={!!currentItem}
            />
            <FormFeedback>{errors.maKho}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="tenKho">
              Tên Kho <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Input
              type="text"
              name="tenKho"
              id="tenKho"
              value={item.tenKho}
              onChange={handleChange}
              invalid={!!errors.tenKho}
            />
            <FormFeedback>{errors.tenKho}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="ghiChu">Ghi Chú</Label>
            <Input type="text" name="ghiChu" id="ghiChu" value={item.ghiChu} onChange={handleChange} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={loading}>
          Lưu
        </Button>
        <Button color="secondary" onClick={toggle}>
          Hủy
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditItemModal;
