import { read, WorkBook } from 'xlsx';
import FileUtil from '../file-util';

abstract class AbstractExcelReader<T> {
  protected workbook: WorkBook | undefined;

  async readData(file: File) {
    const fileData = await FileUtil.fileToUint8Array(file);
    this.workbook = read(fileData, { type: 'array', cellDates: true });
    return this.readDataImpl();
  }

  protected abstract readDataImpl(): T;
}

export default AbstractExcelReader;
