/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreatePhieuNhapTpRequestPartial,
  KhotpTransPhieuNhap,
  KhotpTransPhieuNhapControllerCountParams,
  KhotpTransPhieuNhapControllerFindDetail200,
  KhotpTransPhieuNhapControllerFindDetailForXuat200,
  KhotpTransPhieuNhapControllerFindDetailForXuatParams,
  KhotpTransPhieuNhapControllerFindDetailParams,
  KhotpTransPhieuNhapControllerFindParams,
  KhotpTransPhieuNhapWithRelations,
  LoopbackCount,
  NewKhotpTransPhieuNhap
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpTransPhieuNhapControllerCount = (
    params?: KhotpTransPhieuNhapControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-trans-phieu-nhaps/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuNhapControllerCountQueryKey = (params?: KhotpTransPhieuNhapControllerCountParams,) => {
    return [`/khotp-trans-phieu-nhaps/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuNhapControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuNhapControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuNhapControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>> = ({ signal }) => khotpTransPhieuNhapControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuNhapControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>>
export type KhotpTransPhieuNhapControllerCountQueryError = ErrorType<unknown>

export const useKhotpTransPhieuNhapControllerCount = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuNhapControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuNhapControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuNhapControllerFindDetail = (
    params?: KhotpTransPhieuNhapControllerFindDetailParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuNhapControllerFindDetail200>(
      {url: `/khotp-trans-phieu-nhaps/search-details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuNhapControllerFindDetailQueryKey = (params?: KhotpTransPhieuNhapControllerFindDetailParams,) => {
    return [`/khotp-trans-phieu-nhaps/search-details`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuNhapControllerFindDetailQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuNhapControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuNhapControllerFindDetailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>> = ({ signal }) => khotpTransPhieuNhapControllerFindDetail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuNhapControllerFindDetailQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>>
export type KhotpTransPhieuNhapControllerFindDetailQueryError = ErrorType<unknown>

export const useKhotpTransPhieuNhapControllerFindDetail = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuNhapControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetail>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuNhapControllerFindDetailQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuNhapControllerFindDetailForXuat = (
    params?: KhotpTransPhieuNhapControllerFindDetailForXuatParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuNhapControllerFindDetailForXuat200>(
      {url: `/khotp-trans-phieu-nhaps/search-details-forXuat`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuNhapControllerFindDetailForXuatQueryKey = (params?: KhotpTransPhieuNhapControllerFindDetailForXuatParams,) => {
    return [`/khotp-trans-phieu-nhaps/search-details-forXuat`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuNhapControllerFindDetailForXuatQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuNhapControllerFindDetailForXuatParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuNhapControllerFindDetailForXuatQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>> = ({ signal }) => khotpTransPhieuNhapControllerFindDetailForXuat(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuNhapControllerFindDetailForXuatQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>>
export type KhotpTransPhieuNhapControllerFindDetailForXuatQueryError = ErrorType<unknown>

export const useKhotpTransPhieuNhapControllerFindDetailForXuat = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuNhapControllerFindDetailForXuatParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindDetailForXuat>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuNhapControllerFindDetailForXuatQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuNhapControllerUpdateById = (
    id: number,
    createPhieuNhapTpRequestPartial: CreatePhieuNhapTpRequestPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-phieu-nhaps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: createPhieuNhapTpRequestPartial
    },
      );
    }
  


export const getKhotpTransPhieuNhapControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>, TError,{id: number;data: CreatePhieuNhapTpRequestPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>, TError,{id: number;data: CreatePhieuNhapTpRequestPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>, {id: number;data: CreatePhieuNhapTpRequestPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpTransPhieuNhapControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuNhapControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>>
    export type KhotpTransPhieuNhapControllerUpdateByIdMutationBody = CreatePhieuNhapTpRequestPartial
    export type KhotpTransPhieuNhapControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuNhapControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>, TError,{id: number;data: CreatePhieuNhapTpRequestPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuNhapControllerUpdateById>>,
        TError,
        {id: number;data: CreatePhieuNhapTpRequestPartial},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuNhapControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuNhapControllerFindById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuNhapWithRelations>(
      {url: `/khotp-trans-phieu-nhaps/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getKhotpTransPhieuNhapControllerFindByIdQueryKey = (id: number,) => {
    return [`/khotp-trans-phieu-nhaps/${id}`] as const;
    }

    
export const getKhotpTransPhieuNhapControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuNhapControllerFindByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>> = ({ signal }) => khotpTransPhieuNhapControllerFindById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuNhapControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>>
export type KhotpTransPhieuNhapControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpTransPhieuNhapControllerFindById = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuNhapControllerFindByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuNhapControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-phieu-nhaps/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpTransPhieuNhapControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khotpTransPhieuNhapControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuNhapControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>>
    
    export type KhotpTransPhieuNhapControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuNhapControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuNhapControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuNhapControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuNhapControllerCreate = (
    newKhotpTransPhieuNhap: NewKhotpTransPhieuNhap,
 ) => {
      
      
      return drcInstance<KhotpTransPhieuNhap>(
      {url: `/khotp-trans-phieu-nhaps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpTransPhieuNhap
    },
      );
    }
  


export const getKhotpTransPhieuNhapControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>, TError,{data: NewKhotpTransPhieuNhap}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>, TError,{data: NewKhotpTransPhieuNhap}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>, {data: NewKhotpTransPhieuNhap}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransPhieuNhapControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuNhapControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>>
    export type KhotpTransPhieuNhapControllerCreateMutationBody = NewKhotpTransPhieuNhap
    export type KhotpTransPhieuNhapControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuNhapControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>, TError,{data: NewKhotpTransPhieuNhap}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuNhapControllerCreate>>,
        TError,
        {data: NewKhotpTransPhieuNhap},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuNhapControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuNhapControllerFind = (
    params?: KhotpTransPhieuNhapControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuNhapWithRelations[]>(
      {url: `/khotp-trans-phieu-nhaps`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuNhapControllerFindQueryKey = (params?: KhotpTransPhieuNhapControllerFindParams,) => {
    return [`/khotp-trans-phieu-nhaps`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuNhapControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuNhapControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuNhapControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>> = ({ signal }) => khotpTransPhieuNhapControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuNhapControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>>
export type KhotpTransPhieuNhapControllerFindQueryError = ErrorType<unknown>

export const useKhotpTransPhieuNhapControllerFind = <TData = Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuNhapControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuNhapControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuNhapControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



