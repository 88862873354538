import Dashboard from 'main/components/pages/admin/dashboard/dashboard';
import ProductCustomerExportList from 'main/components/pages/admin/prdouct-customer/prdouct-customer-list';
import ProductCommandExportDetail from 'main/components/pages/admin/product-command-export/product-command-export-detail/product-command-export-detail';
import ProductCommandExportEdit from 'main/components/pages/admin/product-command-export/product-command-export-edit/product-command-export-edit';
import ProductCommandExportList from 'main/components/pages/admin/product-command-export/product-command-export-list/product-command-export-list';
import ProductEnterpriseExportList from 'main/components/pages/admin/product-enterprise/product-enterprise-list';
import ProductGroupExportList from 'main/components/pages/admin/product-group/product-group-list';
import ProductStocksExportList from 'main/components/pages/admin/product-stocks/product-stocks';
import ProductStoreExcelDetail from 'main/components/pages/admin/product-store-excel/product-store-excel-detail/product-store-excel-detail';
import ProductStoreExcelEdit from 'main/components/pages/admin/product-store-excel/product-store-excel-edit/product-store-excel-edit';
import ProductStoreExcelList from 'main/components/pages/admin/product-store-excel/product-store-excel-list/product-store-excel-list';
import ProductStoreExportAdd from 'main/components/pages/admin/product-store-export/product-store-export-add/ProductStoreExportAdd';
import ProductStoreExportDetail from 'main/components/pages/admin/product-store-export/product-store-export-detail/ProductStoreExportDetail';
import ProductStoreExportEdit from 'main/components/pages/admin/product-store-export/product-store-export-edit/ProductStoreExportEdit';
import ProductStoreExportList from 'main/components/pages/admin/product-store-export/product-store-export-list/ProductStoreExportList';
import ProductStoreImportAdd from 'main/components/pages/admin/product-store-import/product-store-import-add/product-store-import-add';
import ProductStoreImportDetail from 'main/components/pages/admin/product-store-import/product-store-import-detail/product-store-import-detail';
import ProductStoreImportEdit from 'main/components/pages/admin/product-store-import/product-store-import-edit/product-store-import-edit';
import ProductStoreImportList from 'main/components/pages/admin/product-store-import/product-store-import-list/product-store-import-list';
import ProductStoreInventoryList from 'main/components/pages/admin/product-store-inventory/product-store-inventory-list/product-store-inventory-list';
import ProductStoreInventoryWarning from 'main/components/pages/admin/product-store-inventory/product-store-inventory-warning/product-store-inventory-warning';
import ProductWarehouseExportList from 'main/components/pages/admin/product-warehouse/product-warehouse-list';
import RequirementsStandardSetCompare from 'main/components/pages/admin/requirements-standard-set/requirements-standard-set-compare/requirements-standard-set-compare';
import RequirementsStandardSetDetail from 'main/components/pages/admin/requirements-standard-set/requirements-standard-set-detail/requirements-standard-set-detail';
import RequirementsStandardSetList from 'main/components/pages/admin/requirements-standard-set/requirements-standard-set-list/requirements-standard-set-list';
import SuppliesCategoryEdit from 'main/components/pages/admin/supplies-category/supplies-category-edit';
import SuppliesCategoryList from 'main/components/pages/admin/supplies-category/supplies-category-list';
import SuppliesGroupExportList from 'main/components/pages/admin/supplies-group/supplies-group-list';
import SuppliesManagementExportList from 'main/components/pages/admin/supplies-management/supplies-management-list';
import SuppliesOriginExportList from 'main/components/pages/admin/supplies-origin/supplies-origin-list';
import SuppliesStoreExportAdd from 'main/components/pages/admin/supplies-store-export/supplies-store-export-add/supplies-store-export-add';
import SuppliesStoreExportDetail from 'main/components/pages/admin/supplies-store-export/supplies-store-export-detail/supplies-store-export-detail';
import SuppliesStoreExportList from 'main/components/pages/admin/supplies-store-export/supplies-store-export-list/supplies-store-export-list';
import SuppliesStoreImportAdd from 'main/components/pages/admin/supplies-store-import/supplies-store-import-add/supplies-store-import-add';
import SuppliesStoreImportDetail from 'main/components/pages/admin/supplies-store-import/supplies-store-import-detail/supplies-store-import-detail';
import SuppliesStoreImportEdit from 'main/components/pages/admin/supplies-store-import/supplies-store-import-edit/supplies-store-import-edit';
import SuppliesStoreImportList from 'main/components/pages/admin/supplies-store-import/supplies-store-import-list/supplies-store-import-list';
import SuppliesStoreInventoryCard from 'main/components/pages/admin/supplies-store-inventory/supplies-store-inventory-card/supplies-store-inventory-card';
import SuppliesStoreInventoryDashboard from 'main/components/pages/admin/supplies-store-inventory/supplies-store-inventory-dashboard/supplies-store-inventory-dashboard';
import SuppliesStoreInventoryList from 'main/components/pages/admin/supplies-store-inventory/supplies-store-inventory-list/supplies-store-inventory-list';
import SuppliesStoreInventoryWarning from 'main/components/pages/admin/supplies-store-inventory/supplies-store-inventory-warning/supplies-store-inventory-warning';
import SuppliesWarehouseExportList from 'main/components/pages/admin/supplies-warehouse/supplies-warehouse-list';
import TermsAndConditionsList from 'main/components/pages/admin/terms-and-conditions/terms-and-conditions-list';
import TitlePermissionManagement from 'main/components/pages/admin/title-permissions-management/title-permission-management';
import UsersManagementList from 'main/components/pages/admin/users-management/users-management-list';
import { Page } from 'main/constants';
import AdminLayout from 'main/layouts/admin';
import { Navigate, Route } from 'react-router-dom';
import PrivateRoute from './private-route';

const adminRouter = (
  <Route
    path="/admin/*"
    element={
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    }>
    {/** Dashboard Routes */}
    <Route path="dashboard" element={<Dashboard />} />

    {/** Supplies Store Import Routes */}
    <Route path="supplies-store-import/list" element={<SuppliesStoreImportList />} />
    <Route path="supplies-store-import/add" element={<SuppliesStoreImportAdd />} />
    <Route path="supplies-store-import/detail/:id" element={<SuppliesStoreImportDetail />} />
    <Route path="supplies-store-import/edit/:id" element={<SuppliesStoreImportEdit />} />

    {/** Supplies Store Export Routes */}
    <Route path="supplies-store-export/list" element={<SuppliesStoreExportList />} />
    <Route path="supplies-store-export/add" element={<SuppliesStoreExportAdd />} />
    <Route path="supplies-store-export/detail/:id" element={<SuppliesStoreExportDetail />} />

    {/** Supplies Store Inventory Routes */}
    <Route path="supplies-store-inventory/list" element={<SuppliesStoreInventoryList />} />
    <Route path="supplies-store-inventory/warning" element={<SuppliesStoreInventoryWarning />} />
    <Route path="supplies-store-inventory/dashboard" element={<SuppliesStoreInventoryDashboard />} />
    <Route path="supplies-store-inventory/card" element={<SuppliesStoreInventoryCard />} />

    {/** Supplies Category Routes */}
    <Route path="supplies-category/list" element={<SuppliesCategoryList />} />
    <Route path="supplies-category/edit" element={<SuppliesCategoryEdit />} />

    {/** Supplies Management Routes */}
    <Route path="supplies-management/list" element={<SuppliesManagementExportList />} />

    {/** Supplies WareHouse Routes */}
    <Route path="supplies-warehouse/list" element={<SuppliesWarehouseExportList />} />

    {/** Supplies Group Routes */}
    <Route path="supplies-group/list" element={<SuppliesGroupExportList />} />

    {/** Supplies Origin Routes */}
    <Route path="supplies-origin/list" element={<SuppliesOriginExportList />} />

    {/** Product Store Import Routes */}
    <Route path="product-store-import/list" element={<ProductStoreImportList />} />
    <Route path="product-store-import/add" element={<ProductStoreImportAdd />} />
    <Route path="product-store-import/detail/:id" element={<ProductStoreImportDetail />} />
    <Route path="product-store-import/edit/:id" element={<ProductStoreImportEdit />} />

    {/** Product Store Excel Routes */}
    <Route path="product-store-excel/list" element={<ProductStoreExcelList />} />
    <Route path="product-store-excel/edit/:id" element={<ProductStoreExcelEdit />} />
    <Route path="product-store-excel/detail/:id" element={<ProductStoreExcelDetail />} />

    {/** Product WareHouse Routes */}
    <Route path="product-warehouse/list" element={<ProductWarehouseExportList />} />

    {/** Product Enterprise Routes */}
    <Route path="product-enterprise/list" element={<ProductEnterpriseExportList />} />

    {/** Product Stocks Routes */}
    <Route path="product/list" element={<ProductStocksExportList />} />

    {/** Product Store Export Routes */}
    <Route path="product-store-export/list" element={<ProductStoreExportList />} />
    <Route path="product-store-export/add" element={<ProductStoreExportAdd />} />
    <Route path="product-store-export/detail/:id" element={<ProductStoreExportDetail />} />
    <Route path="product-store-export/edit/:id" element={<ProductStoreExportEdit />} />

    {/** Product Command Export Routes */}
    <Route path="product-command-export/list" element={<ProductCommandExportList />} />
    <Route path="product-command-export/detail/:id" element={<ProductCommandExportDetail />} />
    <Route path="product-command-export/edit/:id" element={<ProductCommandExportEdit />} />

    {/** Product Store Inventory Routes */}
    <Route path="product-store-inventory/list" element={<ProductStoreInventoryList />} />
    <Route path="product-store-inventory/warning" element={<ProductStoreInventoryWarning />} />

    {/** Product Group Routes */}
    <Route path="product-group/list" element={<ProductGroupExportList />} />

    {/**Product Customer Routes */}
    <Route path="product-customer/list" element={<ProductCustomerExportList />} />

    {/**System Routes */}
    <Route path="system/user/list" element={<UsersManagementList />} />
    <Route path="system/title/list" element={<TitlePermissionManagement />} />
    {/** Requirements Standard Set Routes */}
    <Route path="requirements-standard-set/list" element={<RequirementsStandardSetList />} />
    <Route path="requirements-standard-set/add" element={<></>} />
    <Route path="requirements-standard-set/detail/:id" element={<RequirementsStandardSetDetail />} />
    <Route path="requirements-standard-set/edit/:id" element={<></>} />
    <Route path="requirements-standard-set/compare" element={<RequirementsStandardSetCompare />} />

    {/** Terms And Conditions Routes */}
    <Route path="terms-and-conditions/list" element={<TermsAndConditionsList />} />

    {/** Other Routes */}
    <Route path="*" element={<Navigate to={Page.DASHBOARD.PATH} replace />} />
  </Route>
);

export default adminRouter;
