import { useAuthenticationControllerLogin } from 'main/apis/drc/endpoints/authentication-controller/authentication-controller';
import { Credentials, UserExcludingPassword } from 'main/apis/drc/models';
import { AuthenticationUtil } from 'main/utils';
import { useState } from 'react';

type Props = {
  onSuccess?: () => void;
  onError?: (errorMessage?: string) => void;
};

const useAuth = (props?: Props) => {
  const [userInfo, setUserInfo] = useState<UserExcludingPassword | null>(null);
  const [authenticated, setAuthenticated] = useState(true);
  const loginMutation = useAuthenticationControllerLogin();

  const login = (credential: Credentials) => {
    loginMutation.mutate(
      { data: credential },
      {
        onSuccess: (data) => {
          if (data.token) {
            AuthenticationUtil.setAuthToken(data.token);
            AuthenticationUtil.setUsername(credential.email);
            setUserInfo({
              username: credential.email,
            });
            setAuthenticated(true);

            if (props?.onSuccess) {
              props.onSuccess();
            }
          } else if (props?.onError) {
            props.onError('Đăng nhập thất bại: Thông tin đăng nhập chưa hợp lệ.');
          }
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.error?.message || error.message;
          if (props?.onError) {
            props.onError(errorMessage);
          }
        },
      },
    );
  };

  const logout = () => {
    AuthenticationUtil.deleteAuthToken();
    AuthenticationUtil.deleteUsername();
  };

  return {
    login,
    logout,
    userInfo,
    authenticated,
  };
};

export default useAuth;
