/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterKho,
  KhotpMasterKhoControllerCountParams,
  KhotpMasterKhoControllerFindByIdParams,
  KhotpMasterKhoControllerFindParams,
  KhotpMasterKhoControllerUpdateAllParams,
  KhotpMasterKhoPartial,
  KhotpMasterKhoWithRelations,
  LoopbackCount,
  NewKhotpMasterKho
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterKhoControllerCount = (
    params?: KhotpMasterKhoControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-khos/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhoControllerCountQueryKey = (params?: KhotpMasterKhoControllerCountParams,) => {
    return [`/khotp-master-khos/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhoControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterKhoControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhoControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>> = ({ signal }) => khotpMasterKhoControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhoControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>>
export type KhotpMasterKhoControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterKhoControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterKhoControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhoControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterKhoControllerReplaceById = (
    id: string,
    khotpMasterKho: KhotpMasterKho,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khos/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKho
    },
      );
    }
  


export const getKhotpMasterKhoControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterKho}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterKho}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>, {id: string;data: KhotpMasterKho}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterKhoControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhoControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>>
    export type KhotpMasterKhoControllerReplaceByIdMutationBody = KhotpMasterKho
    export type KhotpMasterKhoControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhoControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterKho}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhoControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterKho},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhoControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhoControllerUpdateById = (
    id: string,
    khotpMasterKhoPartial: KhotpMasterKhoPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khos/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhoPartial
    },
      );
    }
  


export const getKhotpMasterKhoControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhoPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhoPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>, {id: string;data: KhotpMasterKhoPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterKhoControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhoControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>>
    export type KhotpMasterKhoControllerUpdateByIdMutationBody = KhotpMasterKhoPartial
    export type KhotpMasterKhoControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhoControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhoPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterKhoPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhoControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhoControllerFindById = (
    id: string,
    params?: KhotpMasterKhoControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterKhoWithRelations>(
      {url: `/khotp-master-khos/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhoControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterKhoControllerFindByIdParams,) => {
    return [`/khotp-master-khos/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhoControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterKhoControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhoControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>> = ({ signal }) => khotpMasterKhoControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhoControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>>
export type KhotpMasterKhoControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterKhoControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterKhoControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhoControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterKhoControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khos/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterKhoControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterKhoControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhoControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>>
    
    export type KhotpMasterKhoControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhoControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhoControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhoControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhoControllerCreate = (
    newKhotpMasterKho: NewKhotpMasterKho,
 ) => {
      
      
      return drcInstance<KhotpMasterKho>(
      {url: `/khotp-master-khos`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterKho
    },
      );
    }
  


export const getKhotpMasterKhoControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>, TError,{data: NewKhotpMasterKho}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>, TError,{data: NewKhotpMasterKho}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>, {data: NewKhotpMasterKho}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterKhoControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhoControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>>
    export type KhotpMasterKhoControllerCreateMutationBody = NewKhotpMasterKho
    export type KhotpMasterKhoControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterKhoControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>, TError,{data: NewKhotpMasterKho}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhoControllerCreate>>,
        TError,
        {data: NewKhotpMasterKho},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhoControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhoControllerUpdateAll = (
    khotpMasterKhoPartial: KhotpMasterKhoPartial,
    params?: KhotpMasterKhoControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-khos`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhoPartial,
        params
    },
      );
    }
  


export const getKhotpMasterKhoControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>, TError,{data: KhotpMasterKhoPartial;params?: KhotpMasterKhoControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>, TError,{data: KhotpMasterKhoPartial;params?: KhotpMasterKhoControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>, {data: KhotpMasterKhoPartial;params?: KhotpMasterKhoControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterKhoControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhoControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>>
    export type KhotpMasterKhoControllerUpdateAllMutationBody = KhotpMasterKhoPartial
    export type KhotpMasterKhoControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterKhoControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>, TError,{data: KhotpMasterKhoPartial;params?: KhotpMasterKhoControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhoControllerUpdateAll>>,
        TError,
        {data: KhotpMasterKhoPartial;params?: KhotpMasterKhoControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhoControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhoControllerFind = (
    params?: KhotpMasterKhoControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterKhoWithRelations[]>(
      {url: `/khotp-master-khos`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhoControllerFindQueryKey = (params?: KhotpMasterKhoControllerFindParams,) => {
    return [`/khotp-master-khos`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhoControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterKhoControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhoControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>> = ({ signal }) => khotpMasterKhoControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhoControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>>
export type KhotpMasterKhoControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterKhoControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterKhoControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhoControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhoControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



