import { useDmMasterBoDinhMucControllerFindCompare } from 'main/apis/drc/endpoints/dm-master-bo-dinh-muc-controller/dm-master-bo-dinh-muc-controller';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import { useCallback, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import RequirementsStandardSetSelection from './sub-components/requirements-standard-set-selection';
import RequirementsStandardSetCompareTable from './sub-components/requirements-standard-set-table/requirements-standard-set-compare-table';

function RequirementsStandardSetCompare() {
  // React State
  const [leftId, setLeftId] = useState('');
  const [rightId, setRightId] = useState('');
  const [triggerSearchFlag, setTriggerSearchFlag] = useState(false);

  // React Query
  const { data: requirementsStandardCompareItems, isLoading: requirementsStandardCompareItemsIsLoading } =
    useDmMasterBoDinhMucControllerFindCompare(
      {
        boDinhMucId1: Number(leftId),
        boDinhMucId2: Number(rightId),
      },
      {
        query: {
          enabled: !!leftId && !!rightId,
          queryKey: ['requirementsStandardCompareItems', triggerSearchFlag],
          placeholderData: undefined,
        },
      },
    );

  // React Function
  const handleCompareClick = useCallback(
    (selectedLeftId: string, selectedRightId: string) => {
      setLeftId(selectedLeftId);
      setRightId(selectedRightId);
      setTriggerSearchFlag(!triggerSearchFlag);
    },
    [triggerSearchFlag],
  );

  return (
    <BasicTemplate
      pageTitle={Page.REQUIREMENTS_STANDARD_SET_COMPARE.TITLE}
      pageName={Page.REQUIREMENTS_STANDARD_SET_COMPARE.NAME}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>SO SÁNH BỘ ĐỊNH MỨC</h3>
              </CardHeader>
              <CardBody>
                <RequirementsStandardSetSelection
                  leftId={leftId}
                  rightId={rightId}
                  handleCompareClick={handleCompareClick}
                />
                <hr />
                <Row>
                  <Col md={12} lg={6}>
                    <FormGroup className="row">
                      <Col md="12">
                        <RequirementsStandardSetCompareTable
                          isLeft
                          data={requirementsStandardCompareItems}
                          dataIsLoading={requirementsStandardCompareItemsIsLoading}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={6}>
                    <FormGroup className="row">
                      <Col md="12">
                        <RequirementsStandardSetCompareTable
                          data={requirementsStandardCompareItems}
                          dataIsLoading={requirementsStandardCompareItemsIsLoading}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </BasicTemplate>
  );
}

export default RequirementsStandardSetCompare;
