import { Formik } from 'formik';
import { KhotpTransLenhNhapWithRelations } from 'main/apis/drc/models';
import { ValidationMessage } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { ProductStoreImportExcelReader } from 'main/utils/excel-reader';
import { useCallback } from 'react';
import { Form } from 'react-router-dom';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  handleConfirmClickCallback: (productStoreExcel: KhotpTransLenhNhapWithRelations) => void;
};

const PRODUCT_STORE_EXCEL_FILE_ACCEPT =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

function UploadProductStoreExcelModal({ isOpen, toggle, handleConfirmClickCallback }: Props) {
  const productStoreExcelInitialValues: { productStoreExcelFile?: File } = {
    productStoreExcelFile: undefined,
  };

  const productStoreExceValidate = useCallback((values: typeof productStoreExcelInitialValues) => {
    if (!values.productStoreExcelFile) {
      return { productStoreExcelFile: ValidationMessage.REQUIRED };
    }
    if (values.productStoreExcelFile && !PRODUCT_STORE_EXCEL_FILE_ACCEPT.includes(values.productStoreExcelFile.type)) {
      return { productStoreExcelFile: ValidationMessage.INVALID_EXCEL_FILE_TYPE };
    }
  }, []);

  const handleConfirmClick = useCallback(
    async (values: typeof productStoreExcelInitialValues) => {
      if (!values.productStoreExcelFile) {
        return;
      }

      // Transfrom productStoreExcelFile file
      const productStoreExcel = await new ProductStoreImportExcelReader().readData(values.productStoreExcelFile);

      // Callback
      handleConfirmClickCallback(productStoreExcel);
    },
    [handleConfirmClickCallback],
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered fade={false} size="lg">
      <Formik
        initialValues={productStoreExcelInitialValues}
        validate={productStoreExceValidate}
        onSubmit={() => {
          // do nothing
        }}>
        {({ values, dirty, isValid, errors, setFieldValue }) => (
          <>
            <ModalHeader toggle={toggle}>UPLOAD LỆNH NHẬP KHO</ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={12} lg={12}>
                    <FormGroup className="row">
                      <Col md="12">
                        <Label className="form-control-label">
                          File import lệnh nhập &nbsp;
                          <i className="fa fa-asterisk fa-sm text-danger" />
                        </Label>
                        <Input
                          type="file"
                          accept={PRODUCT_STORE_EXCEL_FILE_ACCEPT}
                          name={FieldName.PRODUCT_STORE_EXCEL_FILE}
                          placeholder="Upload file import"
                          onChange={(e) => {
                            if (e.currentTarget.files) {
                              setFieldValue(FieldName.PRODUCT_STORE_EXCEL_FILE, e.currentTarget.files[0]);
                            }
                          }}
                          invalid={!!errors.productStoreExcelFile}
                        />
                        {errors.productStoreExcelFile && (
                          <FormText color="danger">{errors.productStoreExcelFile}</FormText>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter className="justify-content-end">
              <Button
                color="primary"
                onClick={async () => await handleConfirmClick(values)}
                disabled={!dirty || !isValid}>
                Xác nhận import
              </Button>
              <Button color="secondary" onClick={toggle}>
                Hủy
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default UploadProductStoreExcelModal;
