import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  KhovtTransPhieuNhapControllerFindDetail200DataItem,
  KhovtTransPhieuXuatDetailWithRelations,
} from 'main/apis/drc/models';
import { FieldName } from 'main/constants/enums';
import { RootState } from 'main/redux/store';
import { TableState } from 'main/types';
import moment from 'moment';

export type SuppliesFilter = {
  suppliesGroup: string;
  batchCode: string;
  suppliesCode: string;
  suppliesStoreCode: string;
};

type State = {
  suppliesTriggerFlag: boolean;
  suppliesFilter: SuppliesFilter;
  suppliesTable: TableState<KhovtTransPhieuNhapControllerFindDetail200DataItem>;
  suppliesStoreExportDetails: KhovtTransPhieuXuatDetailWithRelations[];
};

// State
const initialState: State = {
  suppliesTriggerFlag: false,
  suppliesFilter: {
    suppliesGroup: '-1',
    batchCode: '',
    suppliesCode: '',
    suppliesStoreCode: '',
  },
  suppliesTable: {
    page: 1,
    sizePerPage: 10,
  },
  suppliesStoreExportDetails: [],
};

// Slice
const suppliesStoreExportAddSlice = createSlice({
  name: 'suppliesStoreExportAdd',
  initialState,
  reducers: {
    syncSuppliesStoreCodeFilter(state, action: PayloadAction<string>) {
      state.suppliesFilter.suppliesStoreCode = action.payload;
    },
    insertNewSupplies(state, action: PayloadAction<KhovtTransPhieuNhapControllerFindDetail200DataItem[] | undefined>) {
      if (!action.payload) {
        return;
      }

      // Convert supplies to suppliesStoreExportDetails
      const newSuppliesDetails: KhovtTransPhieuXuatDetailWithRelations[] = [];
      for (const supplies of action.payload) {
        const { id, maVt, tenVt, soLo, tonKho, nguonGoc } = supplies;
        newSuppliesDetails.push({
          idNhap: id,
          maLo: soLo,
          maVt,
          tenVt,
          nguonGoc,
          tonKho,
          soLuongThucTe: undefined,
          soLuongYeuCau: undefined,
          ngayXuat: moment().toISOString(),
        });
      }

      // Merge and deduplicate
      const updatedSuppliesDetails = _.unionBy(state.suppliesStoreExportDetails, newSuppliesDetails, FieldName.ID_NHAP);

      // Update state
      state.suppliesStoreExportDetails = updatedSuppliesDetails;
    },
    setSuppliesDetails(state, action: PayloadAction<KhovtTransPhieuXuatDetailWithRelations[]>) {
      state.suppliesStoreExportDetails = action.payload;
    },
    updateSuppliesDetail: {
      reducer(state, action: PayloadAction<{ idNhap: number; dataField: string; newValue: any }>) {
        const { idNhap, dataField, newValue } = action.payload;
        const updatedIndex = state.suppliesStoreExportDetails.findIndex(
          (suppliesDetail) => suppliesDetail.idNhap === idNhap,
        );
        if (updatedIndex !== -1) {
          _.set(state.suppliesStoreExportDetails[updatedIndex], dataField, newValue);
        }
      },
      prepare(idNhap, dataField, newValue) {
        return {
          payload: { idNhap, dataField, newValue },
        };
      },
    },
    deleteSuppliesDetail(state, action: PayloadAction<number | null | undefined>) {
      state.suppliesStoreExportDetails = state.suppliesStoreExportDetails.filter(
        (suppliesDetail) => suppliesDetail.idNhap !== action.payload,
      );
    },
    setSuppliesTableSelectedRows(state, action: PayloadAction<KhovtTransPhieuNhapControllerFindDetail200DataItem[]>) {
      state.suppliesTable.selectedRows = action.payload;
    },
    updateSuppliesTable(state, action: PayloadAction<TableState<KhovtTransPhieuNhapControllerFindDetail200DataItem>>) {
      state.suppliesTable = { ...action.payload };
    },
    clickSearchSupplies(state, action: PayloadAction<SuppliesFilter>) {
      state.suppliesFilter = action.payload;
      state.suppliesTable.page = 1;
      state.suppliesTriggerFlag = !state.suppliesTriggerFlag;
    },
    resetSuppliesFilterAndTable(state) {
      state.suppliesFilter = {
        ...initialState.suppliesFilter,
        suppliesStoreCode: state.suppliesFilter.suppliesStoreCode,
      };
      state.suppliesTable = initialState.suppliesTable;
    },
    resetSuppliesStoreExportAddState() {
      return initialState;
    },
  },
});

// Action creators
export const {
  syncSuppliesStoreCodeFilter,
  insertNewSupplies,
  setSuppliesDetails,
  updateSuppliesDetail,
  deleteSuppliesDetail,
  setSuppliesTableSelectedRows,
  updateSuppliesTable,
  clickSearchSupplies,
  resetSuppliesFilterAndTable,
  resetSuppliesStoreExportAddState,
} = suppliesStoreExportAddSlice.actions;

// Selectors
const selectSuppliesStoreExportAdd = (state: RootState) => state.suppliesStoreExportAdd;

export const selectSuppliesDetails = createSelector(
  [selectSuppliesStoreExportAdd],
  (suppliesStoreExportAdd) => suppliesStoreExportAdd.suppliesStoreExportDetails,
);

export const selectSuppliesFilter = createSelector(
  [selectSuppliesStoreExportAdd],
  (suppliesStoreExportAdd) => suppliesStoreExportAdd.suppliesFilter,
);

export const selectSuppliesTable = createSelector(
  [selectSuppliesStoreExportAdd],
  (suppliesStoreExportAdd) => suppliesStoreExportAdd.suppliesTable,
);

export const selectSuppliesTriggerFlag = createSelector(
  [selectSuppliesStoreExportAdd],
  (suppliesStoreExportAdd) => suppliesStoreExportAdd.suppliesTriggerFlag,
);

export const selectSuppliesTableExcludeSelectedRows = createSelector([selectSuppliesTable], (suppliesTable) => ({
  ...suppliesTable,
  selectedRows: undefined,
}));

export const selectSuppliesTableSelectedRows = createSelector(
  [selectSuppliesTable],
  (suppliesTable) => suppliesTable.selectedRows || [],
);

export const selectSuppliesTableSelectedRowIds = createSelector(
  [selectSuppliesTableSelectedRows],
  (suppliesTableSelectedRows) => suppliesTableSelectedRows.map((row) => row.id),
);

export default suppliesStoreExportAddSlice;
