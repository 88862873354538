import { KhotpTransPhieuXuatControllerFindDetail200DataItem } from 'main/apis/drc/models';
import { generateColumnStyle } from 'main/utils';

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'maSanPham',
    text: 'Mã sản phẩm',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'tenSanPham',
    text: 'Tên sản phẩm',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'dvt',
    text: 'Đơn vị tính',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'soLuongYc',
    text: 'Yêu cầu',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'soLuongXuat',
    text: 'Thực xuất',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'tonHienTai',
    text: 'Tồn kho hiện tại',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'tonKho',
    text: 'Tồn kho dự kiến',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'ngayNhap',
    text: 'Tháng sản xuất',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'thoiHanLuuKho',
    text: ' Thời hạn lưu kho',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'ghiChu',
    text: 'Ghi chú',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Thao tác',
    csvExport: false,
    editable: false,
    ...generateColumnStyle(undefined, true),
    formatter: (_: any, row: KhotpTransPhieuXuatControllerFindDetail200DataItem) => {
      return (
        <div>
          <i className="fa-solid fa-trash" title="Xóa" />
        </div>
      );
    },
  },
];

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  clickToEdit: true,
  style: { backgroundColor: '#c8e6c9' },
};

const cellEdit = {
  mode: 'dbclick',
  blurToSave: true,
};

const tableConfig = {
  columns,
  selectRow,
  cellEdit,
};

export default tableConfig;
