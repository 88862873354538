enum FieldName {
  ID = 'id',
  PAGE = 'page',
  SIZE_PER_PAGE = 'sizePerPage',
  SORT_BY = 'sortBy',
  SORT_TYPE = 'sortType',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EXPORT_STORE = 'exportStore',
  IMPORT_STORE = 'importStore',
  EXPORT_BILL_NO = 'exportBillNo',
  EXPORT_STATUS = 'exportStatus',
  NGAY_XUAT = 'ngayXuat',
  KHO_XUAT = 'khoXuat',
  KHO_XUAT_2 = 'KhoXuat',
  KHO_NHAP = 'khoNhap',
  SO_PHIEU = 'soPhieu',
  STATUS = 'status',
  GHI_CHU = 'ghiChu',
  LY_DO_XUAT = 'lyDoXuat',
  NGUOI_TAO_PHIEU = 'nguoiTaoPhieu',
  THONG_TIN_CANH_BAO = 'thongTinCanhBao',
  SUPPLIES_GROUP = 'suppliesGroup',
  BATCH_CODE = 'batchCode',
  SUPPLIES_CODE = 'suppliesCode',
  SUPPLIES_NAME = 'suppliesName',
  SUPPLIES_SUGGESTIVE_NAME = 'suppliesSuggestiveName',
  PRODUCT_NAME = 'productName',
  PRODUCT_SUGGESTIVE_NAME = 'productSuggestiveName',
  MA_VT = 'maVt',
  DETAILS = 'details',
  DETAIL = 'detail',
  ORIGIN = 'origin',
  SEARCH_FIELD = 'searchField',
  SEARCH_KEY = 'searchKey',
  IMPORT_STATUS = 'importStatus',
  MA_KHO = 'maKho',
  SO_PHIEU_NHAP = 'soPhieuNhap',
  DON_VI_NHAP = 'donViNhap',
  VI_TRI_LUU_KHO = 'viTriLuuKho',
  NGAY_NHAP = 'ngayNhap',
  NGUOI_NHAP = 'nguoiNhap',
  XE_VAN_CHUYEN = 'xeVanChuyen',
  SO_HOA_DON = 'soHoaDon',
  NGAY_HOA_DON = 'ngayHoaDon',
  TEN_VT = 'tenVt',
  SO_LO = 'soLo',
  NHOM_VT = 'nhomVt',
  DVT = 'dvt',
  SO_LUONG = 'soLuong',
  TYPE_NHAP = 'typeNhap',
  NGAY_SX = 'ngaySx',
  NGAYSX = 'ngaySX',
  NGAY_HET_HAN = 'ngayHetHan',
  NGAY_HET_HAN_LK = 'ngayHetHanLK',
  NGAY_THI_NGHIEM = 'ngayThiNghiem',
  ACTION = 'action',
  MA_NHOM = 'maNhom',
  TEN_NHOM = 'tenNhom',
  MA_LO = 'maLo',
  NGUON_GOC = 'nguonGoc',
  SO_LUONG_YEU_CAU = 'soLuongYeuCau',
  SO_LUONG_THUC_TE = 'soLuongThucTe',
  SO_LUONG_TRA_HANG = 'soLuongTraHang',
  TON_KHO = 'tonKho',
  TON_KHO_DU_KIEN = 'tonKhoDuKien',
  NGAY_SAN_XUAT = 'ngaySanXuat',
  HAN_SU_DUNG = 'hanSuDung',
  THOI_HAN_LUU_KHO = 'thoiHanLuuKho',
  THOI_HAN_LUU_KHO_2 = 'ThoiHanLuuKho',
  PHIEU_NHAP_ID = 'phieuNhapId',
  ID_NHAP = 'idNhap',
  IMPORT_TYPE = 'importType',
  MA_SAN_PHAM = 'maSanPham',
  TEN_SAN_PHAM = 'tenSanPham',
  TEN_GOI_NHO = 'tenGoiNho',
  MA_KHO_NHAP = 'maKhoNhap',
  MA_PHIEU = 'maPhieu',
  LOAI_PHIEU = 'loaiPhieu',
  NGUOI_NHAN_HANG = 'nguoiNhanHang',
  VI_TRI = 'viTri',
  SO_XE = 'soXe',
  LOAI_XE = 'loaiXe',
  PRODUCT_GROUP = 'productGroup',
  PRODUCT_TYPE = 'productType',
  PRODUCT_CODE = 'productCode',
  MA_LOAI = 'maLoai',
  UNIQUE_KEY = 'uniqueKey',
  LY_DO_TRA_HANG = 'lyDoTraHang',
  NGAY_TRA_HANG = 'ngayTraHang',
  NHOMVT_TEN_NHOM = 'nhomvt.tenNhom',
  NHOMSP_TEN_NHOM = 'nhomsp.tenNhom',
  MONTH = 'month',
  SUPPLIES_STORE = 'suppliesStore',
  PRODUCT_STORE = 'productStore',
  SO_LUONG_NHAP_PREVIOUS_MONTH = 'soLuongNhapPreviousMonth',
  SO_LUONG_XUAT_PREVIOUS_MONTH = 'soLuongXuatPreviousMonth',
  SO_LUONG_NHAP_MONTH = 'soLuongNhapMonth',
  SO_LUONG_XUAT_MONTH = 'soLuongXuatMonth',
  SO_LUONG_NHAP = 'soLuongNhap',
  SO_LUONG_XUAT = 'soLuongXuat',
  TEN_NHA_SX = 'tenNhaSX',
  TEN_KHO = 'tenKho',
  DOCUMENT_NAME = 'documentName',
  DOCUMENT_CODE = 'documentCode',
  MA_TAI_LIEU = 'maTaiLieu',
  TEN_TAI_LIEU = 'tenTaiLieu',
  URL_TAI_LIEU = 'urlTaiLieu',
  NGAY_BAN_HANH = 'ngayBanHanh',
  LAN_BAN_HANH = 'lanBanHanh',
  NGUOI_BAN_HANH = 'nguoiBanHanh',
  NGUOI_THUC_HIEN = 'nguoiThucHien',

  // product
  DIA_CHI = 'diaChi',
  DIA_CHI_KH = 'diaChiKh',
  HINH_THUC_THANH_TOAN = 'hinhThucThanhToan',
  KHACH_HANG = 'khachHang',
  MA_KH = 'maKh',
  MA_SO_THUE = 'maSoThue',
  MA_SO_THUE_KH = 'maSoThueKh',
  NGAY_XUAT_KHO = 'ngayXuatKho',
  NGUOI_VAN_CHUYEN = 'nguoiVanChuyen',
  NGUOI_XUAT_HANG = 'nguoiXuatHang',
  SDT = 'sdt',
  DT_KH = 'dtKh',
  FAX_KH = 'faxKh',
  LH_KH = 'lienHeKh',
  MST_KH = 'mstKh',
  SO_CHUNG_TU = 'soChungTu',
  SO_TAI_KHOAN = 'soTaiKhoan',
  MA_KHO_XUAT = 'maKhoXuat',
  NGAY_CHUNG_TU = 'ngayChungTu',
  ID_PHIEU_XUAT = 'idPhieuXuat',
  HAN_LUU_KHO = 'hanSD',
  THANG_NHAP_KHO = 'thangNhapKho',
  TEN_KH = 'tenKh',
  YEU_CAU_XUAT = 'yeuCauXuat',
  THUC_XUAT = 'thucXuat',
  PRODUCT_COMMAND_EXPORT_FILE = 'productCommandExportFile',

  // STORAGE KEY
  AUTH_TOKEN_KEY = 'authToken',
  USERNAME_KEY = 'username',
}

export default FieldName;
