import moment from 'moment';
import { WorkBook, utils } from 'xlsx';

const ExcelUtil = {
  normalizeCellData(data?: string, dateNF?: string) {
    const normalizedData = data ? String(data).trim() : '';
    if (!dateNF) {
      return normalizedData;
    }
    return normalizedData ? moment(normalizedData).format(dateNF).toString() : '';
  },
  getSheetRange(workbook: WorkBook, sheetName: string) {
    const worksheet = workbook.Sheets[sheetName];
    const sheetRange = worksheet?.['!ref'];
    if (!sheetRange) {
      throw Error('Can not get sheet range, please check the input data file.');
    }
    return utils.decode_range(sheetRange);
  },
  loadDataByRange(
    workbook: WorkBook,
    sheetName: string,
    range: {
      colBeginIndex: number;
      rowBeginIndex: number;
      colEndIndex: number;
      rowEndIndex: number;
    },
  ): string[][] {
    const worksheet = workbook.Sheets[sheetName];
    return utils.sheet_to_json(worksheet, {
      range: {
        s: { c: range.colBeginIndex, r: range.rowBeginIndex },
        e: { c: range.colEndIndex, r: range.rowEndIndex },
      },
      raw: true,
      rawNumbers: true,
      header: 1,
      defval: null,
    });
  },
};

export default ExcelUtil;
