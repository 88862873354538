/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterLoaiSpControllerCountParams,
  KhotpMasterLoaiSpControllerFindByIdParams,
  KhotpMasterLoaiSpControllerFindParams,
  KhotpMasterLoaiSpControllerUpdateAllParams,
  KhotpMasterLoaisp,
  KhotpMasterLoaispPartial,
  KhotpMasterLoaispWithRelations,
  LoopbackCount,
  NewKhotpMasterLoaisp
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterLoaiSpControllerCount = (
    params?: KhotpMasterLoaiSpControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-loaisps/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterLoaiSpControllerCountQueryKey = (params?: KhotpMasterLoaiSpControllerCountParams,) => {
    return [`/khotp-master-loaisps/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterLoaiSpControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterLoaiSpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterLoaiSpControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>> = ({ signal }) => khotpMasterLoaiSpControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterLoaiSpControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>>
export type KhotpMasterLoaiSpControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterLoaiSpControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterLoaiSpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterLoaiSpControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterLoaiSpControllerReplaceById = (
    id: string,
    khotpMasterLoaisp: KhotpMasterLoaisp,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-loaisps/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterLoaisp
    },
      );
    }
  


export const getKhotpMasterLoaiSpControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterLoaisp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterLoaisp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>, {id: string;data: KhotpMasterLoaisp}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterLoaiSpControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterLoaiSpControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>>
    export type KhotpMasterLoaiSpControllerReplaceByIdMutationBody = KhotpMasterLoaisp
    export type KhotpMasterLoaiSpControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterLoaiSpControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>, TError,{id: string;data: KhotpMasterLoaisp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterLoaiSpControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterLoaisp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterLoaiSpControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterLoaiSpControllerUpdateById = (
    id: string,
    khotpMasterLoaispPartial: KhotpMasterLoaispPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-loaisps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterLoaispPartial
    },
      );
    }
  


export const getKhotpMasterLoaiSpControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterLoaispPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterLoaispPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>, {id: string;data: KhotpMasterLoaispPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterLoaiSpControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterLoaiSpControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>>
    export type KhotpMasterLoaiSpControllerUpdateByIdMutationBody = KhotpMasterLoaispPartial
    export type KhotpMasterLoaiSpControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterLoaiSpControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>, TError,{id: string;data: KhotpMasterLoaispPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterLoaispPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterLoaiSpControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterLoaiSpControllerFindById = (
    id: string,
    params?: KhotpMasterLoaiSpControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterLoaispWithRelations>(
      {url: `/khotp-master-loaisps/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterLoaiSpControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterLoaiSpControllerFindByIdParams,) => {
    return [`/khotp-master-loaisps/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterLoaiSpControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterLoaiSpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterLoaiSpControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>> = ({ signal }) => khotpMasterLoaiSpControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterLoaiSpControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>>
export type KhotpMasterLoaiSpControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterLoaiSpControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterLoaiSpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterLoaiSpControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterLoaiSpControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-loaisps/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterLoaiSpControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterLoaiSpControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterLoaiSpControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>>
    
    export type KhotpMasterLoaiSpControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterLoaiSpControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterLoaiSpControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterLoaiSpControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterLoaiSpControllerCreate = (
    newKhotpMasterLoaisp: NewKhotpMasterLoaisp,
 ) => {
      
      
      return drcInstance<KhotpMasterLoaisp>(
      {url: `/khotp-master-loaisps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterLoaisp
    },
      );
    }
  


export const getKhotpMasterLoaiSpControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>, TError,{data: NewKhotpMasterLoaisp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>, TError,{data: NewKhotpMasterLoaisp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>, {data: NewKhotpMasterLoaisp}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterLoaiSpControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterLoaiSpControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>>
    export type KhotpMasterLoaiSpControllerCreateMutationBody = NewKhotpMasterLoaisp
    export type KhotpMasterLoaiSpControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterLoaiSpControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>, TError,{data: NewKhotpMasterLoaisp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterLoaiSpControllerCreate>>,
        TError,
        {data: NewKhotpMasterLoaisp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterLoaiSpControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterLoaiSpControllerUpdateAll = (
    khotpMasterLoaispPartial: KhotpMasterLoaispPartial,
    params?: KhotpMasterLoaiSpControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-loaisps`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterLoaispPartial,
        params
    },
      );
    }
  


export const getKhotpMasterLoaiSpControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>, TError,{data: KhotpMasterLoaispPartial;params?: KhotpMasterLoaiSpControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>, TError,{data: KhotpMasterLoaispPartial;params?: KhotpMasterLoaiSpControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>, {data: KhotpMasterLoaispPartial;params?: KhotpMasterLoaiSpControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterLoaiSpControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterLoaiSpControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>>
    export type KhotpMasterLoaiSpControllerUpdateAllMutationBody = KhotpMasterLoaispPartial
    export type KhotpMasterLoaiSpControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterLoaiSpControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>, TError,{data: KhotpMasterLoaispPartial;params?: KhotpMasterLoaiSpControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterLoaiSpControllerUpdateAll>>,
        TError,
        {data: KhotpMasterLoaispPartial;params?: KhotpMasterLoaiSpControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterLoaiSpControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterLoaiSpControllerFind = (
    params?: KhotpMasterLoaiSpControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterLoaispWithRelations[]>(
      {url: `/khotp-master-loaisps`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterLoaiSpControllerFindQueryKey = (params?: KhotpMasterLoaiSpControllerFindParams,) => {
    return [`/khotp-master-loaisps`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterLoaiSpControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterLoaiSpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterLoaiSpControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>> = ({ signal }) => khotpMasterLoaiSpControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterLoaiSpControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>>
export type KhotpMasterLoaiSpControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterLoaiSpControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterLoaiSpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterLoaiSpControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterLoaiSpControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



