/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreatePhieuXuatRequest,
  KhovtTransPhieuXuatControllerCountParams,
  KhovtTransPhieuXuatControllerFindDetail200,
  KhovtTransPhieuXuatControllerFindDetailParams,
  KhovtTransPhieuXuatControllerFindParams,
  KhovtTransPhieuXuatControllerTraHang200,
  KhovtTransPhieuXuatWithRelations,
  LoopbackCount,
  TraHangModel
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtTransPhieuXuatControllerCreate = (
    createPhieuXuatRequest: CreatePhieuXuatRequest,
 ) => {
      
      
      return drcInstance<KhovtTransPhieuXuatWithRelations>(
      {url: `/khovt-trans-phieu-xuat`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPhieuXuatRequest
    },
      );
    }
  


export const getKhovtTransPhieuXuatControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>, TError,{data: CreatePhieuXuatRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>, TError,{data: CreatePhieuXuatRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>, {data: CreatePhieuXuatRequest}> = (props) => {
          const {data} = props ?? {};

          return  khovtTransPhieuXuatControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtTransPhieuXuatControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>>
    export type KhovtTransPhieuXuatControllerCreateMutationBody = CreatePhieuXuatRequest
    export type KhovtTransPhieuXuatControllerCreateMutationError = ErrorType<unknown>

    export const useKhovtTransPhieuXuatControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>, TError,{data: CreatePhieuXuatRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCreate>>,
        TError,
        {data: CreatePhieuXuatRequest},
        TContext
      > => {

      const mutationOptions = getKhovtTransPhieuXuatControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtTransPhieuXuatControllerCount = (
    params?: KhovtTransPhieuXuatControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khovt-trans-phieu-xuats/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtTransPhieuXuatControllerCountQueryKey = (params?: KhovtTransPhieuXuatControllerCountParams,) => {
    return [`/khovt-trans-phieu-xuats/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtTransPhieuXuatControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>, TError = ErrorType<unknown>>(params?: KhovtTransPhieuXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtTransPhieuXuatControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>> = ({ signal }) => khovtTransPhieuXuatControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtTransPhieuXuatControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>>
export type KhovtTransPhieuXuatControllerCountQueryError = ErrorType<unknown>

export const useKhovtTransPhieuXuatControllerCount = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhovtTransPhieuXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtTransPhieuXuatControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtTransPhieuXuatControllerFindDetail = (
    params?: KhovtTransPhieuXuatControllerFindDetailParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtTransPhieuXuatControllerFindDetail200>(
      {url: `/khovt-trans-phieu-xuats/search-details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtTransPhieuXuatControllerFindDetailQueryKey = (params?: KhovtTransPhieuXuatControllerFindDetailParams,) => {
    return [`/khovt-trans-phieu-xuats/search-details`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtTransPhieuXuatControllerFindDetailQueryOptions = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>, TError = ErrorType<unknown>>(params?: KhovtTransPhieuXuatControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtTransPhieuXuatControllerFindDetailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>> = ({ signal }) => khovtTransPhieuXuatControllerFindDetail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtTransPhieuXuatControllerFindDetailQueryResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>>
export type KhovtTransPhieuXuatControllerFindDetailQueryError = ErrorType<unknown>

export const useKhovtTransPhieuXuatControllerFindDetail = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>, TError = ErrorType<unknown>>(
 params?: KhovtTransPhieuXuatControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindDetail>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtTransPhieuXuatControllerFindDetailQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtTransPhieuXuatControllerTraHang = (
    traHangModel: TraHangModel,
 ) => {
      
      
      return drcInstance<KhovtTransPhieuXuatControllerTraHang200>(
      {url: `/khovt-trans-phieu-xuats/tra-hang`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: traHangModel
    },
      );
    }
  


export const getKhovtTransPhieuXuatControllerTraHangMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>, TError,{data: TraHangModel}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>, TError,{data: TraHangModel}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>, {data: TraHangModel}> = (props) => {
          const {data} = props ?? {};

          return  khovtTransPhieuXuatControllerTraHang(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtTransPhieuXuatControllerTraHangMutationResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>>
    export type KhovtTransPhieuXuatControllerTraHangMutationBody = TraHangModel
    export type KhovtTransPhieuXuatControllerTraHangMutationError = ErrorType<unknown>

    export const useKhovtTransPhieuXuatControllerTraHang = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>, TError,{data: TraHangModel}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtTransPhieuXuatControllerTraHang>>,
        TError,
        {data: TraHangModel},
        TContext
      > => {

      const mutationOptions = getKhovtTransPhieuXuatControllerTraHangMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtTransPhieuXuatControllerUpdateById = (
    id: number,
    createPhieuXuatRequest: CreatePhieuXuatRequest,
 ) => {
      
      
      return drcInstance<KhovtTransPhieuXuatWithRelations>(
      {url: `/khovt-trans-phieu-xuats/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: createPhieuXuatRequest
    },
      );
    }
  


export const getKhovtTransPhieuXuatControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>, {id: number;data: CreatePhieuXuatRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  khovtTransPhieuXuatControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtTransPhieuXuatControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>>
    export type KhovtTransPhieuXuatControllerUpdateByIdMutationBody = CreatePhieuXuatRequest
    export type KhovtTransPhieuXuatControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhovtTransPhieuXuatControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtTransPhieuXuatControllerUpdateById>>,
        TError,
        {id: number;data: CreatePhieuXuatRequest},
        TContext
      > => {

      const mutationOptions = getKhovtTransPhieuXuatControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtTransPhieuXuatControllerFindById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtTransPhieuXuatWithRelations>(
      {url: `/khovt-trans-phieu-xuats/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getKhovtTransPhieuXuatControllerFindByIdQueryKey = (id: number,) => {
    return [`/khovt-trans-phieu-xuats/${id}`] as const;
    }

    
export const getKhovtTransPhieuXuatControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtTransPhieuXuatControllerFindByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>> = ({ signal }) => khovtTransPhieuXuatControllerFindById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtTransPhieuXuatControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>>
export type KhovtTransPhieuXuatControllerFindByIdQueryError = ErrorType<unknown>

export const useKhovtTransPhieuXuatControllerFindById = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtTransPhieuXuatControllerFindByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtTransPhieuXuatControllerCancelById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-trans-phieu-xuats/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhovtTransPhieuXuatControllerCancelByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khovtTransPhieuXuatControllerCancelById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtTransPhieuXuatControllerCancelByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>>
    
    export type KhovtTransPhieuXuatControllerCancelByIdMutationError = ErrorType<unknown>

    export const useKhovtTransPhieuXuatControllerCancelById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtTransPhieuXuatControllerCancelById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhovtTransPhieuXuatControllerCancelByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtTransPhieuXuatControllerFind = (
    params?: KhovtTransPhieuXuatControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtTransPhieuXuatWithRelations[]>(
      {url: `/khovt-trans-phieu-xuats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtTransPhieuXuatControllerFindQueryKey = (params?: KhovtTransPhieuXuatControllerFindParams,) => {
    return [`/khovt-trans-phieu-xuats`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtTransPhieuXuatControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtTransPhieuXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtTransPhieuXuatControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>> = ({ signal }) => khovtTransPhieuXuatControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtTransPhieuXuatControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>>
export type KhovtTransPhieuXuatControllerFindQueryError = ErrorType<unknown>

export const useKhovtTransPhieuXuatControllerFind = <TData = Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtTransPhieuXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtTransPhieuXuatControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtTransPhieuXuatControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



