/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpXuatNhapTonControllerFind200,
  KhotpXuatNhapTonControllerFindParams,
  KhotpXuatNhapTonControllerSearchCanhBao200Item
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpXuatNhapTonControllerSearchCanhBao = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpXuatNhapTonControllerSearchCanhBao200Item[]>(
      {url: `/khotp-xuat-nhap-tons/search-canh-bao`, method: 'GET', signal
    },
      );
    }
  

export const getKhotpXuatNhapTonControllerSearchCanhBaoQueryKey = () => {
    return [`/khotp-xuat-nhap-tons/search-canh-bao`] as const;
    }

    
export const getKhotpXuatNhapTonControllerSearchCanhBaoQueryOptions = <TData = Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpXuatNhapTonControllerSearchCanhBaoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>> = ({ signal }) => khotpXuatNhapTonControllerSearchCanhBao(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpXuatNhapTonControllerSearchCanhBaoQueryResult = NonNullable<Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>>
export type KhotpXuatNhapTonControllerSearchCanhBaoQueryError = ErrorType<unknown>

export const useKhotpXuatNhapTonControllerSearchCanhBao = <TData = Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerSearchCanhBao>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpXuatNhapTonControllerSearchCanhBaoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpXuatNhapTonControllerFind = (
    params?: KhotpXuatNhapTonControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpXuatNhapTonControllerFind200>(
      {url: `/khotp-xuat-nhap-tons`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpXuatNhapTonControllerFindQueryKey = (params?: KhotpXuatNhapTonControllerFindParams,) => {
    return [`/khotp-xuat-nhap-tons`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpXuatNhapTonControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpXuatNhapTonControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpXuatNhapTonControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>> = ({ signal }) => khotpXuatNhapTonControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpXuatNhapTonControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>>
export type KhotpXuatNhapTonControllerFindQueryError = ErrorType<unknown>

export const useKhotpXuatNhapTonControllerFind = <TData = Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpXuatNhapTonControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpXuatNhapTonControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpXuatNhapTonControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



