import { FieldName } from 'main/constants/enums';
import { generateColumnStyle } from 'main/utils';
import { SizePerPageRendererOptions } from 'react-bootstrap-table-next';

const columns = [
  {
    dataField: FieldName.NHOMSP_TEN_NHOM,
    text: 'Nhóm sản phẩm',
    sort: true,
    ...generateColumnStyle('15%'),
  },
  {
    dataField: FieldName.MA_SAN_PHAM,
    text: 'Mã sản phẩm',
    sort: true,
    ...generateColumnStyle('15%'),
  },
  {
    dataField: FieldName.TEN_SAN_PHAM,
    text: 'Tên sản phẩm',
    sort: true,
    ...generateColumnStyle('20%'),
  },
  {
    dataField: FieldName.TEN_GOI_NHO,
    text: 'Tên gợi nhớ',
    sort: true,
    ...generateColumnStyle('20%'),
  },
  {
    dataField: FieldName.DVT,
    text: 'Đơn vị tính',
    sort: true,
    ...generateColumnStyle('10%'),
  },
  {
    dataField: FieldName.GHI_CHU,
    text: 'Ghi chú',
    ...generateColumnStyle('20%'),
  },
];

const pagination = {
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: true,
  sizePerPageRenderer: ({ onSizePerPageChange }: SizePerPageRendererOptions) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Hiển thị&nbsp;
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(Number(e.target.value))}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        &nbsp;mục.
      </label>
    </div>
  ),
  paginationTotalRenderer: (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;Hiển thị hàng {from} đến {to} trên {size}
    </span>
  ),
};

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
};

const tableConfig = {
  columns,
  pagination,
  selectRow,
};

export default tableConfig;
