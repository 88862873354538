/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhovtMasterDonVi,
  KhovtMasterDonViControllerCountParams,
  KhovtMasterDonViControllerFindByIdParams,
  KhovtMasterDonViControllerFindParams,
  KhovtMasterDonViPartial,
  KhovtMasterDonViWithRelations,
  LoopbackCount,
  NewKhovtMasterDonVi
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtMasterDonViControllerCount = (
    params?: KhovtMasterDonViControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khovt-master-don-vis/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterDonViControllerCountQueryKey = (params?: KhovtMasterDonViControllerCountParams,) => {
    return [`/khovt-master-don-vis/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterDonViControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>, TError = ErrorType<unknown>>(params?: KhovtMasterDonViControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterDonViControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>> = ({ signal }) => khovtMasterDonViControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterDonViControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>>
export type KhovtMasterDonViControllerCountQueryError = ErrorType<unknown>

export const useKhovtMasterDonViControllerCount = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterDonViControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterDonViControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterDonViControllerUpdateById = (
    id: number,
    khovtMasterDonViPartial: KhovtMasterDonViPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-don-vis/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khovtMasterDonViPartial
    },
      );
    }
  


export const getKhovtMasterDonViControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>, TError,{id: number;data: KhovtMasterDonViPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>, TError,{id: number;data: KhovtMasterDonViPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>, {id: number;data: KhovtMasterDonViPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khovtMasterDonViControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterDonViControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>>
    export type KhovtMasterDonViControllerUpdateByIdMutationBody = KhovtMasterDonViPartial
    export type KhovtMasterDonViControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhovtMasterDonViControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>, TError,{id: number;data: KhovtMasterDonViPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterDonViControllerUpdateById>>,
        TError,
        {id: number;data: KhovtMasterDonViPartial},
        TContext
      > => {

      const mutationOptions = getKhovtMasterDonViControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterDonViControllerFindById = (
    id: number,
    params?: KhovtMasterDonViControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterDonViWithRelations>(
      {url: `/khovt-master-don-vis/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterDonViControllerFindByIdQueryKey = (id: number,
    params?: KhovtMasterDonViControllerFindByIdParams,) => {
    return [`/khovt-master-don-vis/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterDonViControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: KhovtMasterDonViControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterDonViControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>> = ({ signal }) => khovtMasterDonViControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterDonViControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>>
export type KhovtMasterDonViControllerFindByIdQueryError = ErrorType<unknown>

export const useKhovtMasterDonViControllerFindById = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhovtMasterDonViControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterDonViControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterDonViControllerCreate = (
    newKhovtMasterDonVi: NewKhovtMasterDonVi,
 ) => {
      
      
      return drcInstance<KhovtMasterDonVi>(
      {url: `/khovt-master-don-vis`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhovtMasterDonVi
    },
      );
    }
  


export const getKhovtMasterDonViControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>, TError,{data: NewKhovtMasterDonVi}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>, TError,{data: NewKhovtMasterDonVi}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>, {data: NewKhovtMasterDonVi}> = (props) => {
          const {data} = props ?? {};

          return  khovtMasterDonViControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterDonViControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>>
    export type KhovtMasterDonViControllerCreateMutationBody = NewKhovtMasterDonVi
    export type KhovtMasterDonViControllerCreateMutationError = ErrorType<unknown>

    export const useKhovtMasterDonViControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>, TError,{data: NewKhovtMasterDonVi}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterDonViControllerCreate>>,
        TError,
        {data: NewKhovtMasterDonVi},
        TContext
      > => {

      const mutationOptions = getKhovtMasterDonViControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterDonViControllerFind = (
    params?: KhovtMasterDonViControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterDonViWithRelations[]>(
      {url: `/khovt-master-don-vis`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterDonViControllerFindQueryKey = (params?: KhovtMasterDonViControllerFindParams,) => {
    return [`/khovt-master-don-vis`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterDonViControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtMasterDonViControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterDonViControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>> = ({ signal }) => khovtMasterDonViControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterDonViControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>>
export type KhovtMasterDonViControllerFindQueryError = ErrorType<unknown>

export const useKhovtMasterDonViControllerFind = <TData = Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterDonViControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterDonViControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterDonViControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



