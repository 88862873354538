import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationUtil } from 'main/utils';

const dsKhoVtPermission: number[] | null =
  AuthenticationUtil.getDsKhoVtPermission()
    ?.split(',')
    ?.map((maKho) => +maKho) ?? null;
const dsKhoTpPermission: string[] | null = AuthenticationUtil.getDsKhoTpPermission()?.split(',') ?? null;

type AuthState = {
  username: string;
  dsKhoVtPermission: number[] | null;
  dsKhoTpPermission: string[] | null;
};

// State
const initialState: AuthState = {
  username: '',
  dsKhoVtPermission,
  dsKhoTpPermission,
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authReset() {
      AuthenticationUtil.deleteDsKhoVtPermission();
      AuthenticationUtil.deleteDsKhoTpPermission();
      return initialState;
    },
    setAuth(auth: AuthState, action: PayloadAction<AuthState>) {
      AuthenticationUtil.setDsKhoVtPermission(action.payload.dsKhoVtPermission?.join(','));
      AuthenticationUtil.setDsKhoTpPermission(action.payload.dsKhoTpPermission?.join(','));
      return action.payload;
    },
  },
});

// Action creators
export const { authReset, setAuth } = authSlice.actions;

export default authSlice;
