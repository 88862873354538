import Select2 from 'react-select2-wrapper';
import styled from 'styled-components';

function Select2Box(props: any) {
  return (
    <Select2Wrapper invalid={props.invalid}>
      <Select2 {...props} />
    </Select2Wrapper>
  );
}

type Select2WrapperProps = {
  invalid?: boolean;
};

const Select2Wrapper = styled.div<Select2WrapperProps>`
  .select2-selection {
    font-size: 0.875rem;
    height: calc(1.5em + 1.25rem + 5px);
    ${(props) => (props.invalid ? 'border-color: #fb6340;' : '')}

    .select2-selection__placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }
`;

export default Select2Box;
