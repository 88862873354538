import { DateFormat } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { cellDateFormatter, generateColumnStyle } from 'main/utils';
import { SizePerPageRendererOptions } from 'react-bootstrap-table-next';

const columns = [
  {
    dataField: FieldName.ID,
    hidden: true,
  },
  {
    dataField: FieldName.SO_PHIEU_NHAP,
    text: 'Số phiếu nhập',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.TEN_NHOM,
    text: 'Nhóm vật tư',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.MA_VT,
    text: 'Mã vật tư',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.TEN_VT,
    text: 'Tên vật tư',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.DVT,
    text: 'Đơn vị tính',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.SO_LO,
    text: 'Mã lô',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.NGAY_SX,
    text: 'Ngày sản xuất',
    sort: true,
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.NGAY_HET_HAN,
    text: 'Hạn sử dụng',
    sort: true,
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.NGUON_GOC,
    text: 'Nguồn gốc',
    sort: true,
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.NGAY_NHAP,
    text: 'Ngày nhập kho',
    sort: true,
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.NGAY_HET_HAN_LK,
    text: 'Thời hạn lưu kho',
    sort: true,
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(),
  },
  {
    dataField: FieldName.TON_KHO,
    text: 'Tồn kho hiện tại',
    sort: true,
  },
];

const cellEdit = {
  mode: 'dbclick',
  blurToSave: true,
};

const pagination = {
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: true,
  sizePerPageRenderer: ({ onSizePerPageChange }: SizePerPageRendererOptions) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Hiển thị&nbsp;
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(Number(e.target.value))}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        &nbsp;mục.
      </label>
    </div>
  ),
  paginationTotalRenderer: (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      &nbsp;Hiển thị hàng {from} đến {to} trên {size}
    </span>
  ),
};

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
};

const tableConfig = {
  columns,
  cellEdit,
  pagination,
  selectRow,
};

export default tableConfig;
