import { KhovtTransPhieuXuatDetailWithRelations } from 'main/apis/drc/models';
import { ValidationMessage } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { cellQuantityValidator, generateColumnStyle } from 'main/utils';

const columns = [
  {
    dataField: FieldName.ID_NHAP,
    hidden: true,
  },
  {
    dataField: FieldName.MA_VT,
    text: 'Mã vật tư',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.TEN_VT,
    text: 'Tên vật tư',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.MA_LO,
    text: 'Mã số lô',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.NGUON_GOC,
    text: 'Nguồn gốc',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.SO_LUONG_YEU_CAU,
    text: 'Số lượng yêu cầu',
    ...generateColumnStyle(),
    validator: cellQuantityValidator(true),
  },
  {
    dataField: FieldName.SO_LUONG_THUC_TE,
    text: 'Số lượng thực xuất',
    ...generateColumnStyle(),
    validator: (newValue: string, row: KhovtTransPhieuXuatDetailWithRelations, column: any) => {
      const quantityValidationResult = cellQuantityValidator(true)(newValue, row, column);
      if (quantityValidationResult !== true) {
        return quantityValidationResult;
      }

      if (Number(newValue) > Number(row.tonKho)) {
        return {
          valid: false,
          message: ValidationMessage.INVALID_SO_LUONG_THUC_XUAT,
        };
      }

      return true;
    },
  },
  {
    dataField: FieldName.TON_KHO,
    text: 'Tồn kho hiện tại',
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.TON_KHO_DU_KIEN,
    text: 'Tồn kho dự kiến',
    formatter: (_: string, row: KhovtTransPhieuXuatDetailWithRelations) => {
      return `${Number(row.tonKho) - Number(row.soLuongThucTe)}`;
    },
    editable: false,
    ...generateColumnStyle(undefined, true),
  },
  /*
  {
    dataField: FieldName.NGAY_SX,
    text: 'Ngày sản xuất',
    editable: false,
    ...generateColumnStyle(undefined, true),
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
  },
  {
    dataField: FieldName.NGAY_HET_HAN,
    text: 'Hạn sử dụng',
    editable: false,
    ...generateColumnStyle(undefined, true),
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
  },
  {
    dataField: FieldName.THOI_HAN_LUU_KHO,
    text: 'Thời hạn lưu kho',
    editable: false,
    ...generateColumnStyle(undefined, true),
    formatter: cellDateFormatter(DateFormat.MM_YYYY_VN),
  },
  */
];

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  clickToEdit: true,
  style: { backgroundColor: '#c8e6c9' },
};

const cellEdit = {
  mode: 'dbclick',
  blurToSave: true,
};

const tableConfig = {
  columns,
  selectRow,
  cellEdit,
};

export default tableConfig;
