/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhovtMasterVt,
  KhovtMasterVtControllerCountParams,
  KhovtMasterVtControllerFindByIdParams,
  KhovtMasterVtControllerFindParams,
  KhovtMasterVtPartial,
  KhovtMasterVtWithRelations,
  LoopbackCount,
  NewKhovtMasterVt
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtMasterVtControllerCount = (
    params?: KhovtMasterVtControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khovt-master-vts/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterVtControllerCountQueryKey = (params?: KhovtMasterVtControllerCountParams,) => {
    return [`/khovt-master-vts/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterVtControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerCount>>, TError = ErrorType<unknown>>(params?: KhovtMasterVtControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterVtControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterVtControllerCount>>> = ({ signal }) => khovtMasterVtControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterVtControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerCount>>>
export type KhovtMasterVtControllerCountQueryError = ErrorType<unknown>

export const useKhovtMasterVtControllerCount = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterVtControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterVtControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterVtControllerUpdateById = (
    id: string,
    khovtMasterVtPartial: KhovtMasterVtPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-vts/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khovtMasterVtPartial
    },
      );
    }
  


export const getKhovtMasterVtControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>, TError,{id: string;data: KhovtMasterVtPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>, TError,{id: string;data: KhovtMasterVtPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>, {id: string;data: KhovtMasterVtPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khovtMasterVtControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterVtControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>>
    export type KhovtMasterVtControllerUpdateByIdMutationBody = KhovtMasterVtPartial
    export type KhovtMasterVtControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhovtMasterVtControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>, TError,{id: string;data: KhovtMasterVtPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterVtControllerUpdateById>>,
        TError,
        {id: string;data: KhovtMasterVtPartial},
        TContext
      > => {

      const mutationOptions = getKhovtMasterVtControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterVtControllerFindById = (
    id: string,
    params?: KhovtMasterVtControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterVtWithRelations>(
      {url: `/khovt-master-vts/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterVtControllerFindByIdQueryKey = (id: string,
    params?: KhovtMasterVtControllerFindByIdParams,) => {
    return [`/khovt-master-vts/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterVtControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhovtMasterVtControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterVtControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>> = ({ signal }) => khovtMasterVtControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterVtControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>>
export type KhovtMasterVtControllerFindByIdQueryError = ErrorType<unknown>

export const useKhovtMasterVtControllerFindById = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhovtMasterVtControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterVtControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtMasterVtControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-vts/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhovtMasterVtControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khovtMasterVtControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterVtControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>>
    
    export type KhovtMasterVtControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhovtMasterVtControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterVtControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhovtMasterVtControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterVtControllerCreate = (
    newKhovtMasterVt: NewKhovtMasterVt,
 ) => {
      
      
      return drcInstance<KhovtMasterVt>(
      {url: `/khovt-master-vts`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhovtMasterVt
    },
      );
    }
  


export const getKhovtMasterVtControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>, TError,{data: NewKhovtMasterVt}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>, TError,{data: NewKhovtMasterVt}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>, {data: NewKhovtMasterVt}> = (props) => {
          const {data} = props ?? {};

          return  khovtMasterVtControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtMasterVtControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>>
    export type KhovtMasterVtControllerCreateMutationBody = NewKhovtMasterVt
    export type KhovtMasterVtControllerCreateMutationError = ErrorType<unknown>

    export const useKhovtMasterVtControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>, TError,{data: NewKhovtMasterVt}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtMasterVtControllerCreate>>,
        TError,
        {data: NewKhovtMasterVt},
        TContext
      > => {

      const mutationOptions = getKhovtMasterVtControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtMasterVtControllerFind = (
    params?: KhovtMasterVtControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterVtWithRelations[]>(
      {url: `/khovt-master-vts`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtMasterVtControllerFindQueryKey = (params?: KhovtMasterVtControllerFindParams,) => {
    return [`/khovt-master-vts`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtMasterVtControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtMasterVtControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtMasterVtControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtMasterVtControllerFind>>> = ({ signal }) => khovtMasterVtControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtMasterVtControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtMasterVtControllerFind>>>
export type KhovtMasterVtControllerFindQueryError = ErrorType<unknown>

export const useKhovtMasterVtControllerFind = <TData = Awaited<ReturnType<typeof khovtMasterVtControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtMasterVtControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtMasterVtControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtMasterVtControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



