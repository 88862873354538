/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhovtMasterNhom,
  KhovtMasterNhomPartial,
  KhovtMasterNhomWithRelations,
  KhovtNhomControllerCountParams,
  KhovtNhomControllerFindByIdParams,
  KhovtNhomControllerFindParams,
  LoopbackCount,
  NewKhovtMasterNhom
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtNhomControllerCount = (
    params?: KhovtNhomControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khovt-master-nhoms/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtNhomControllerCountQueryKey = (params?: KhovtNhomControllerCountParams,) => {
    return [`/khovt-master-nhoms/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtNhomControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khovtNhomControllerCount>>, TError = ErrorType<unknown>>(params?: KhovtNhomControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtNhomControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtNhomControllerCount>>> = ({ signal }) => khovtNhomControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtNhomControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerCount>>>
export type KhovtNhomControllerCountQueryError = ErrorType<unknown>

export const useKhovtNhomControllerCount = <TData = Awaited<ReturnType<typeof khovtNhomControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhovtNhomControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtNhomControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtNhomControllerUpdateById = (
    id: number,
    khovtMasterNhomPartial: KhovtMasterNhomPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-nhoms/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khovtMasterNhomPartial
    },
      );
    }
  


export const getKhovtNhomControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>, TError,{id: number;data: KhovtMasterNhomPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>, TError,{id: number;data: KhovtMasterNhomPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>, {id: number;data: KhovtMasterNhomPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khovtNhomControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtNhomControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>>
    export type KhovtNhomControllerUpdateByIdMutationBody = KhovtMasterNhomPartial
    export type KhovtNhomControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhovtNhomControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>, TError,{id: number;data: KhovtMasterNhomPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtNhomControllerUpdateById>>,
        TError,
        {id: number;data: KhovtMasterNhomPartial},
        TContext
      > => {

      const mutationOptions = getKhovtNhomControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtNhomControllerFindById = (
    id: number,
    params?: KhovtNhomControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterNhomWithRelations>(
      {url: `/khovt-master-nhoms/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtNhomControllerFindByIdQueryKey = (id: number,
    params?: KhovtNhomControllerFindByIdParams,) => {
    return [`/khovt-master-nhoms/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtNhomControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khovtNhomControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: KhovtNhomControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtNhomControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtNhomControllerFindById>>> = ({ signal }) => khovtNhomControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtNhomControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerFindById>>>
export type KhovtNhomControllerFindByIdQueryError = ErrorType<unknown>

export const useKhovtNhomControllerFindById = <TData = Awaited<ReturnType<typeof khovtNhomControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhovtNhomControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtNhomControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtNhomControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khovt-master-nhoms/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhovtNhomControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khovtNhomControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtNhomControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>>
    
    export type KhovtNhomControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhovtNhomControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtNhomControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhovtNhomControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtNhomControllerCreate = (
    newKhovtMasterNhom: NewKhovtMasterNhom,
 ) => {
      
      
      return drcInstance<KhovtMasterNhom>(
      {url: `/khovt-master-nhoms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhovtMasterNhom
    },
      );
    }
  


export const getKhovtNhomControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerCreate>>, TError,{data: NewKhovtMasterNhom}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerCreate>>, TError,{data: NewKhovtMasterNhom}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khovtNhomControllerCreate>>, {data: NewKhovtMasterNhom}> = (props) => {
          const {data} = props ?? {};

          return  khovtNhomControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhovtNhomControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerCreate>>>
    export type KhovtNhomControllerCreateMutationBody = NewKhovtMasterNhom
    export type KhovtNhomControllerCreateMutationError = ErrorType<unknown>

    export const useKhovtNhomControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khovtNhomControllerCreate>>, TError,{data: NewKhovtMasterNhom}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khovtNhomControllerCreate>>,
        TError,
        {data: NewKhovtMasterNhom},
        TContext
      > => {

      const mutationOptions = getKhovtNhomControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khovtNhomControllerFind = (
    params?: KhovtNhomControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtMasterNhomWithRelations[]>(
      {url: `/khovt-master-nhoms`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtNhomControllerFindQueryKey = (params?: KhovtNhomControllerFindParams,) => {
    return [`/khovt-master-nhoms`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtNhomControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtNhomControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtNhomControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtNhomControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtNhomControllerFind>>> = ({ signal }) => khovtNhomControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtNhomControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtNhomControllerFind>>>
export type KhovtNhomControllerFindQueryError = ErrorType<unknown>

export const useKhovtNhomControllerFind = <TData = Awaited<ReturnType<typeof khovtNhomControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtNhomControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtNhomControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtNhomControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



