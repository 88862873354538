import { useKhovtMasterKhoControllerFind } from 'main/apis/drc/endpoints/khovt-master-kho-controller/khovt-master-kho-controller';
import { KhovtMasterKhoControllerFindParams } from 'main/apis/drc/models';
import { useAppSelector } from 'main/redux/hooks';
import { TableSelectOptions } from 'main/types/table-select-options';
import { useCallback, useMemo } from 'react';

function useSuppliesStoreOptions(includeAllOption?: boolean, allowedKho: boolean = false) {
  const dsKhoVtPermission = useAppSelector((state) => state.auth.dsKhoVtPermission);
  const filter: KhovtMasterKhoControllerFindParams =
    dsKhoVtPermission && allowedKho
      ? {
          filter: {
            where: {
              id: {
                inq: dsKhoVtPermission,
              },
            },
          },
        }
      : {};

  // React Query
  const { data: suppliesStores, isLoading: suppliesStoresIsLoading } = useKhovtMasterKhoControllerFind(filter);

  // React Function
  const suppliesStoreOptions = useMemo(() => {
    const options: TableSelectOptions[] = [];

    if (includeAllOption) {
      options.push({ id: -1, text: 'Tất cả' });
    }

    if (suppliesStores) {
      options.push(...suppliesStores.map((suppliesStore) => ({ id: suppliesStore.id!, text: suppliesStore.tenKho })));
    }

    return options;
  }, [includeAllOption, suppliesStores]);

  const getSuppliesStoreOptionTextById = useCallback(
    (id: number) => {
      if (!suppliesStoreOptions) {
        return '';
      }
      for (const suppliesStoreOption of suppliesStoreOptions) {
        if (suppliesStoreOption.id === id) {
          return suppliesStoreOption.text;
        }
      }
      return '';
    },
    [suppliesStoreOptions],
  );

  return { suppliesStoreOptions, suppliesStoresIsLoading, getSuppliesStoreOptionTextById };
}

export default useSuppliesStoreOptions;
